import {
  conjunction,
  fromModel,
  or
} from '../../../dynamicForm/helpers/dynamicFormHelpers'


const calculateLoanAmount = or(conjunction('subtract')(fromModel('assetPrice'), fromModel('proposedDeposit')), fromModel('assetPrice'))

export {
  calculateLoanAmount
}
