import {
  borrowerName,
  borrowerPhone,
  borrowerEmail,
  businessFullComposite,
  contactType,
  loanAmount,
  loanOriginationFixed,
  loanPurposeFixed,
  nCinoLeadId,
  reeceAccountNumber, assetPrice,
  vendorSourceFixed
} from '../dynamicEnquiryFormHelpers'
import {
  container,
  helpContent,
  ruleRender,
  ruleValidation,
  conjunction,
  and,
  fromModel,
  isModelDefined
} from '../../../dynamicForm/helpers/dynamicFormHelpers'

const isLoanAmount = isModelDefined('loanAmount')
const isLeadId = isModelDefined('leadId')
const isReeceTradeAccount = isModelDefined('reeceTradeAccount')
const isBusinessEntity = and(isModelDefined('businessABN'), isModelDefined('businessName'))
const isBorrower = and(isModelDefined('borrowerName'), isModelDefined('borrowerPhone'), isModelDefined('borrowerEmail'))

const validateAssetPrice = and(
  isModelDefined('assetPrice'),
  isModelDefined('loanAmount'),
  conjunction('fle')( fromModel('assetPrice'), fromModel('loanAmount')))

const toolsFinanceAdminEnquiryForm = {
  title: 'Admin tools finance scenario',
  model: 'tools-finance-enquiry-application',
  actionLabel: 'Submit enquiry',
  widgets: [
    container()
      .prompt('Tools finance application')
      .classes('border')
      .widgets(
        // Fixed values.
        loanPurposeFixed().toolsFinance().build(),
        contactType().admin().build(),
        loanOriginationFixed().laddr().build(),
        vendorSourceFixed().reece().build(),
        helpContent().contentId('enquiry-form-tools-finance-application-main').build(),
        // Variable values.
        container()
          .boxed(5)
          .prompt('Loan enquiry references')
          .widgets(
            nCinoLeadId().build(),
            reeceAccountNumber()
              .prompt('Reece trade account number')
              .required(true)
              .addRules(ruleRender(isLeadId))
              .build(),
          ).build(),
        container()
          .boxed(5)
          .prompt('Borrowing entity')
          .rules(ruleRender(isReeceTradeAccount))
          .widgets(
            ...businessFullComposite()
          ).build(),
        container()
          .boxed(5)
          .prompt('Borrower details')
          .rules(ruleRender(isBusinessEntity))
          .widgets(
            helpContent().contentId('enquiry-form-borrower-contact-direct').build(),
            borrowerName().build(),
            borrowerPhone().required(false).build(),
            borrowerEmail().build()
          ).build(),
        container()
          .boxed(5)
          .prompt('Amounts')
          .rules(ruleRender(isBorrower))
          .widgets(
            helpContent().contentId('enquiry-form-tools-finance-amounts').build(),
            loanAmount()
              .prompt('Loan facility (limit) amount')
              .min(1000)
              .max(15000)
              .required(true)
              .decimals(2)
              .build(),
            assetPrice()
              .prompt(`Tools purchase price`)
              .min(1000)
              .max(15000)
              .decimals(2)
              .required(true)
              .addRules(
                ruleRender(isLoanAmount),
                ruleValidation(validateAssetPrice, 'Asset price must be less than or equal to the loan facility amount.')
              )
              .build()
          ).build(),
      ).build()
  ]
}

export default toolsFinanceAdminEnquiryForm
