import {
  assetNewOrUsed,
  assetPrice,
  assetRideShareElectricVehicleFixed,
  borrowerEmail,
  borrowerName,
  borrowerPhone,
  businessFullComposite,
  contactType,
  fileUploadBuilder,
  immigrationVisaType,
  isAssetNewOrUsed,
  isAssetPrice,
  isBorrowerEmail,
  isBorrowerName,
  isReferrerEmail,
  isReferrerName,
  isReferrerNotes,
  isReferrerPhone,
  isResidencyStatus,
  isVehicleModel,
  isVendorSource,
  isYearOfManufacture,
  loanAmount, loanOriginationFixed,
  loanPurposeAdmin,
  originationFee,
  preferredCommission,
  propertyOwner,
  proposedDeposit,
  referrerEmail,
  referrerName,
  referrerNotes,
  referrerPhone,
  residencyStatus,
  vehicleModel,
  vendorSource,
  yearOfManufacture
} from '../dynamicEnquiryFormHelpers'
import {
  and,
  conjunction,
  container,
  fromModel,
  helpContent,
  isModelDefined,
  radioButtons,
  ruleRender,
  ruleSet
} from '../../../dynamicForm/helpers/dynamicFormHelpers'
import {calculateLoanAmount} from './sharedUtilities'


const isBorrower = and(isBorrowerEmail, isBorrowerName)
const isBusinessEntity = and(isModelDefined('businessABN'), isModelDefined('businessName'))
const isReferrer = and(isReferrerEmail, isReferrerName, isReferrerPhone)

const residencyStatusModel = 'residencyStatus'
const isResidencyStatusVisaHolder = and(isModelDefined(residencyStatusModel), conjunction('eq')(fromModel(residencyStatusModel), '"Australian Visa Holder"'))

const rideshareAdminEnquiryForm = {
  title: 'Admin equipment finance scenario',
  model: 'rideshare-finance-enquiry-application',
  actionLabel: 'Submit enquiry',
  widgets: [
    container()
      .prompt('Rideshare Vehicle Finance application (ADMIN)')
      .classes('border')
      .widgets(
        loanPurposeAdmin()
          .required(true)
          .display('none')
          .rules(ruleSet('(or "asset")'))
          .build(),
        contactType().admin().build(),
        loanOriginationFixed().truepillars().build(),
        helpContent().contentId('enquiry-form-rideshare-application-main').build(),
        container()
          .boxed(5)
          .widgets(
            assetPrice()
              .prompt('Asset purchase price')
              .build(),
            vehicleModel()
              .rules(ruleRender(isAssetPrice))
              .build(),
            assetRideShareElectricVehicleFixed()
              .yes()
              .build(),
            vendorSource()
              .buttonsInclude('Dealer')
              .rules(ruleRender(isVehicleModel))
              .build(),
            assetNewOrUsed()
              .rules(ruleRender(isVendorSource))
              .build(),
            yearOfManufacture()
              .required(true)
              .prompt('Vehicle year')
              .addRules(ruleRender(isAssetNewOrUsed))
              .build(),
            proposedDeposit()
              .prompt('Deposit amount (if applicable)')
              .rules(ruleRender(isYearOfManufacture))
              .build(),
            loanAmount()
              .prompt('Financed amount (calculated, display only)')
              .min(3000)
              .required(false)
              .rules(ruleRender(isYearOfManufacture), ruleSet(calculateLoanAmount))
              .display('read-only')
              .build(),
            residencyStatus()
              .rules(ruleRender(isYearOfManufacture))
              .build(),
            immigrationVisaType()
              .addRules(ruleRender(isResidencyStatusVisaHolder))
              .build(),
            preferredCommission()
              .prompt('What is your preferred commission?')
              .min(0)
              .max(7)
              .step(0.5)
              .required(true)
              .rules(ruleRender(isResidencyStatus))
              .build(),
            originationFee()
              .rules(ruleRender(isResidencyStatus))
              .build()
          ).build(),
        container()
          .boxed(5)
          .rules(ruleRender(isResidencyStatus))
          .widgets(
            ...businessFullComposite(),
            referrerNotes()
              .prompt('Describe the business background, and the reason for the loan')
              .required(true)
              .rules(ruleRender(isBusinessEntity))
              .build()
          ).build(),
        container()
          .boxed(5)
          .prompt('Broker details')
          .rules(ruleRender(and(isBusinessEntity, isReferrerNotes)))
          .widgets(
            referrerName().build(),
            referrerPhone().prompt('Your broker mobile number').build(),
            referrerEmail().prompt('Your broker email address').build()
          ).build(),
        container()
          .boxed(5)
          .prompt('Borrower details')
          .rules(ruleRender(isReferrer))
          .widgets(
            helpContent().contentId('enquiry-form-borrower-contact-direct').build(),
            borrowerName().build(),
            borrowerPhone().required(false).build(),
            borrowerEmail().build(),
            radioButtons()
              .model('hasGuarantor')
              .prompt('Is there a guarantor, other than the borrower, for this loan?')
              .button('Guarantor', 'yes')
              .button('Not required', 'no')
              .build(),
            container()
              .boxed(5)
              .prompt('Guarantor details')
              .rules(ruleRender(and(isModelDefined('hasGuarantor'), conjunction('eq')(fromModel('hasGuarantor'), '"yes"'))))
              .widgets(
                borrowerName().model('guarantorName').prompt('Guarantor\'s name').build(),
                borrowerPhone().model('guarantorPhone').prompt('Guarantor\'s mobile').required(false).build(),
                borrowerEmail().model('guarantorEmail').prompt('Guarantor\'s email address').build()).build(),
            propertyOwner().prompt('Does the person providing the guarantee on the loan own property?').required(true).build(),
          ).build(),
        container()
          .boxed(5)
          .prompt('Documents')
          .rules(ruleRender(and(isReferrer, isBorrower)))
          .widgets(
            fileUploadBuilder()
              .fileGroup('rideshareDriverProfile', 'Uber profile screen shot (required)')
              .fileGroup('residencyStatus', 'Proof of residency status (required)')
              .fileGroup('driversLicense', 'Drivers license (required)')
              .fileGroup('councilRates', 'Council rates notices, if property backed')
              .fileGroup('assetInvoices', 'Invoice for the asset')
              .fileGroup('otherDocuments', 'Any other relevant documents')
              .includeComments(false)
              .build())
          .build())
      .build()
  ]
}

export default rideshareAdminEnquiryForm
