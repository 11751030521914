import {loanEnquiryDetailsByStatus, loanEnquiryDetailsSortByUpdatedAt, extractEntity} from '../../../../utils/loanEnquiryDetailHelper'
import resolveProperty from '../../../../utils/resolveProperty'
import makeAddRepresentative from '../representatives/addRepresentative'
import nameSplitter from '../../../../utils/nameSplitter'

const FIELD_NAMES_BORROWER = ['borrowerName', 'borrowerPhone', 'borrowerEmail']
const FIELD_NAMES_GUARANTOR = ['guarantorName', 'guarantorPhone', 'guarantorEmail']
const FIELD_NAMES_COLLATERAL = ['propertyOwner']
const FIELD_NAMES_ASSET_VENDOR_SOURCE = ['vendorSource']
const FIELD_NAMES_ASSET_PROPOSED_DEPOSIT = ['proposedDeposit']
const FIELD_NAMES_TRAIL_BOOK_TARGET = ['trailBookLoanTarget']
const FIELD_NAMES_ASSET_PRICE = ['assetPrice']

class SummaryController {
  /*@ngInject*/
  constructor($scope, $q, $mdDialog, $state) {
    this.name = 'enquiry-details-summary'
    this.$scope = $scope
    this.$q = $q
    this.$mdDialog = $mdDialog
    this.$state = $state

    this.representativeUsers = []
  }

  $onInit() {
    this.$scope.$on('loanEnquiryEvent', (event, ...args) => {
      if (args[0] === 'reloadRepresentatives') {
        event.preventDefault()
        event.stopPropagation()
        this.$state.reload()
      }
    })

    this.loanEnquiry.promise('loanEnquiryDetails').then(loanEnquiryDetails => {
      const mostRecentCompletedLoanEnquiryDetails = loanEnquiryDetailsSortByUpdatedAt(loanEnquiryDetailsByStatus(loanEnquiryDetails, 'complete'))

      this.referrerDetails = (() => {
        const mostRecentLoanEnquiryDetailsWithReferrer = mostRecentCompletedLoanEnquiryDetails
          .find(loanEnquiryDetails => loanEnquiryDetails.response && loanEnquiryDetails.response.referrerDetails)
        return mostRecentLoanEnquiryDetailsWithReferrer ? mostRecentLoanEnquiryDetailsWithReferrer.response.referrerDetails : undefined
      })()

      this.nCinoLeadId = extractEntity(loanEnquiryDetails, ['leadId'])

      this.borrowerDetails = extractEntity(loanEnquiryDetails, FIELD_NAMES_BORROWER)
      if (this.borrowerDetails) {
        const nameParts = nameSplitter(resolveProperty(this.borrowerDetails, 'borrowerName'))
        const defaultValues = {
          firstName: nameParts.firstName,
          middleName: nameParts.middleName,
          lastName: nameParts.lastName,
          email: this.borrowerDetails.borrowerEmail,
          phone: this.borrowerDetails.borrowerPhone
        }
        this.addBorrowerRepresentative = makeAddRepresentative(this.$scope, this.$q, this.$mdDialog, this.loanEnquiry, () => defaultValues)
      }

      this.guarantorDetails = extractEntity(loanEnquiryDetails, FIELD_NAMES_GUARANTOR)
      if (this.guarantorDetails) {
        const nameParts = nameSplitter(resolveProperty(this.guarantorDetails, 'guarantorName'))
        const defaultValues = {
          firstName: nameParts.firstName,
          middleName: nameParts.middleName,
          lastName: nameParts.lastName,
          email: this.guarantorDetails.guarantorEmail,
          phone: this.guarantorDetails.guarantorPhone
        }
        this.addGuarantorRepresentative = makeAddRepresentative(this.$scope, this.$q, this.$mdDialog, this.loanEnquiry, () => defaultValues)
      }

      this.assetDetails = {}
      extractEntity(loanEnquiryDetails, FIELD_NAMES_ASSET_VENDOR_SOURCE, this.assetDetails)
      extractEntity(loanEnquiryDetails, FIELD_NAMES_ASSET_PROPOSED_DEPOSIT, this.assetDetails)
      extractEntity(loanEnquiryDetails, FIELD_NAMES_ASSET_PRICE, this.assetDetails)

      this.trailBook = extractEntity(loanEnquiryDetails, FIELD_NAMES_TRAIL_BOOK_TARGET)
      this.collateralDetails = extractEntity(loanEnquiryDetails, FIELD_NAMES_COLLATERAL)
    })

    this.loanEnquiry.promise('representatives').then((representatives) => {
      representatives.forEach((representative) => {
        representative.promise('user').then((user) => {
          this.representativeUsers.push(user)
        })
      })
    })
  }

  isRepresentative(borrower) {
    return this.representativeUsers.find((representativeUser) => {
      const nameParts = nameSplitter(borrower['borrowerName'])
      return borrower['borrowerEmail'] === representativeUser.email &&
        nameParts.firstName === representativeUser.firstName &&
        nameParts.lastName === representativeUser.lastName
    })
  }
}

export default SummaryController
