// Components for re-use in form definitions.

import assert from '../../../utils/assert'
import createEnum from '../../../utils/createEnum'
import resolveProperty from '../../../utils/resolveProperty'


const LOOKUP_STATUS_VALUES = createEnum('INIT', 'IN_PROGRESS', 'DONE', 'FAILED')


// ****** utility builder

const createBuilder = (spec, ...methodDefinitions) => () => {
  const definition = JSON.parse(JSON.stringify(spec.definition))
  const builder = Object.entries(spec.definition).reduce((candidateBuilder, [key, value]) => {
    // candidateBuilder[key] = (newValue) => {
    const builderSetter = (newValue) => {
      definition[key] = newValue
      return candidateBuilder
    }
    candidateBuilder[key] = Array.isArray(value) ? (...newValues) => builderSetter(newValues) : builderSetter
    return candidateBuilder
  }, {})
  methodDefinitions.forEach(methodDefinition => {
    builder[methodDefinition.name] = (...newValues) => {
      methodDefinition.action(definition, newValues)
      return builder
    }
  })
  const verifyBuilder = (methodName) => assert(
    builder[methodName] === undefined,
    () => `Builder already has a method named '${methodName}', from field collision in spec.definition. ${JSON.stringify(spec)}`)
  verifyBuilder('build')
  builder['build'] = () => {
    if (definition.hasOwnProperty('model') && (!definition['model'] || definition['model'].toString().trim() === '')) {
      throw new SyntaxError(`Form element ${spec.type} must have a model defined.`)
    }
    return {
      type: spec.type,
      definition: definition
    }
  }
  return builder
}


// ****** Fundamental types.

const displayMethods = [
  {
    name: 'readOnly',
    action: (definition) => definition.display = 'read-only'
  },
  {
    name: 'hidden',
    action: (definition) => definition.display = 'none'
  }
]

const optionalMethod = {
  name: 'optional',
  action: (definition) => definition.required = false
}

const ruleMethods = [
  {
    name: 'addRules',
    action: (definition, rules) => {
      if (definition.rules === undefined) {
        definition.rules = []
      }
      if (!Array.isArray(definition.rules)) {
        throw new Error(`Attempt to add rules to non-rule attribute: ${definition}`)
      }
      definition.rules.push(...rules)
    }
  }
]


// ****** rules

const ruleRender = (ruleDefinition) => ({type: 'render', rule: ruleDefinition})
const ruleSet = (ruleDefinition) => ({type: 'set', rule: ruleDefinition})
const ruleValidation = (ruleDefinition, message = 'Invalid value.') => ({type: 'validation', rule: ruleDefinition, message: message})

const conjunction = (rel) => (...predicates) => `(${rel} ` + predicates.join(' ') + ')'
const and = conjunction('and')
const or = conjunction('or')
const singleArgumentOperand = (operand, argument) => `(${operand} ${argument})`
const not = (expr) => singleArgumentOperand('not', expr)
const fromModel = (modelName) => singleArgumentOperand('fromModel', `"${modelName}"`)
const isDefined = (thing) => singleArgumentOperand('isDefined', thing)
const isModelDefined = (modelName) => isDefined(fromModel(modelName))


// ****** resolve choice content

const resolveChoices = (contentful, choicesDefinition) => {
  let status = LOOKUP_STATUS_VALUES.INIT
  const choiceMap = new Map()
  const result = {
    set status(value) {
      status = value
    },
    get isLookupInProgress() {
      return status === LOOKUP_STATUS_VALUES.IN_PROGRESS
    },
    get isLookupFailed() {
      return status === LOOKUP_STATUS_VALUES.FAILED
    },
    get isLookupSuccessful() {
      return status === LOOKUP_STATUS_VALUES.DONE
    },
    choices: [],
    errorMessage: '',
    lookup(key) {
      return choiceMap.get(key)
    }
  }

  const loadChoiceMap = () => {
    result.choices.forEach((choice) => {
      choiceMap.set(choice.value, choice.label)
    })
  }

  const lookupFailure = (errorMessage) => {
    result.errorMessage = errorMessage
    result.status = LOOKUP_STATUS_VALUES.FAILED
  }

  if (Array.isArray(choicesDefinition)) {
    result.choices = choicesDefinition
    loadChoiceMap()
    result.status = LOOKUP_STATUS_VALUES.DONE
  } else if (choicesDefinition.hasOwnProperty('contentId')) {
    result.status = LOOKUP_STATUS_VALUES.IN_PROGRESS
    contentful.entries(`content_type=choice&fields.name=${choicesDefinition.contentId}&limit=200&include=10`)
      .then((entry) => {
        const choices = resolveProperty(entry, 'data', 'items', 0, 'fields', 'choiceItems')
        if (choices !== undefined && choices !== null && Array.isArray(choices) && choices.length > 0) {
          result.choices = choices.map((choice) => ({
            value: choice.fields.key,
            label: choice.fields.label
          }))
          loadChoiceMap()
          result.status = LOOKUP_STATUS_VALUES.DONE
        } else {
          lookupFailure(`NOT FOUND: Choice options for content id '${choicesDefinition.contentId}'.`)
        }
      })
      .catch((e) => lookupFailure(e))
  } else {
    lookupFailure(`Badly formed choices object: ${JSON.stringify(choicesDefinition)}`)
  }
  return result
}


// -------------- WIDGETS ----------------


// ****** Container

const container = createBuilder(
  {
    type: 'Container',
    definition: {
      rules: [],
      prompt: '',
      classes: '',
      widgets: []
    }
  },
  {
    name: 'boxed',
    action: (definition, [level]) => definition.classes = `distinct border box-shadow-${level}`
  }
)


// ****** Currency

const fCurrency = createBuilder(
  {
    type: 'Currency',
    definition: {
      model: '',
      prompt: '',
      required: true,
      decimals: 0,
      display: '',
      validation: [
        {type: 'min', value: 0},
        {type: 'max', value: 500000}
      ],
      rules: []
    }
  },
  {
    name: 'min',
    action: (definition, [min]) => definition.validation[0].value = min
  },
  {
    name: 'max',
    action: (definition, [max]) => definition.validation[1].value = max
  },
  {
    name: 'decimals',
    action: (definition, [decimalPlaces]) => definition.decimals = parseInt(decimalPlaces, 10)
  },
  ...ruleMethods
)


// ****** Email

const fEmail = () => createBuilder(
  {
    type: 'Email',
    definition: {
      prompt: '',
      model: '',
      required: false
    }
  }
)


// ****** helpContent

const helpContent = createBuilder(
  {
    type: 'HelpContent',
    definition: {
      contentId: '',
      rules: []
    }
  }
)


// ****** Phone

const fPhone = () => createBuilder(
  {
    type: 'Phone',
    definition: {
      prompt: '',
      model: '',
      required: false
    }
  }
)


// ****** Phone - Australian mobile

const fPhoneAusMobile = () => createBuilder(
  {
    type: 'PhoneAusMobile',
    definition: {
      model: '',
      prompt: '',
      required: false
    }
  }
)


// ****** RadioButtons

const fRadioButtons = () => createBuilder(
  {
    type: 'RadioButtons',
    definition: {
      prompt: '',
      model: '',
      required: false,
      radioButtons: [],
      rules: [],
      layout: 'inline-radio-buttons'
    }
  },
  {
    name: 'button',
    action: (definition, [label, value]) => definition.radioButtons.push({label: label, value: value})
  },
  {
    name: 'buttonsInclude',
    action: (definition, [...labels]) => {
      if (Array.isArray(definition.radioButtons)) {
        definition.radioButtons = definition.radioButtons.filter((buttonDefinition) => labels.includes(buttonDefinition.label))
      }
    }
  },
  {
    name: 'contentId',
    action: (definition, [contentId]) => definition.radioButtons = {contentId}
  },
  {
    name: 'inline',
    action: (definition) => definition.layout = 'inline-radio-buttons'
  },
  {
    name: 'block',
    action: (definition) => definition.layout = 'block-radio-buttons'
  }
)
const radioButtons = () => fRadioButtons()()


// ****** select

const fSelect = () => createBuilder(
  {
    type: 'Select',
    definition: {
      prompt: '',
      model: '',
      required: false,
      choices: [],
      rules: [],
    }
  },
  {
    name: 'choice',
    action: (definition, [label, value]) => definition.choices.push({label: label, value: value})
  },
  {
    name: 'contentId',
    action: (definition, [contentId]) => definition.choices = {contentId}
  }
)
const select = () => fSelect()()


// ****** Slider

const fSlider = createBuilder(
  {
    type: 'Slider',
    definition: {
      model: '',
      prompt: '',
      required: false,
      validation: [
        {type: 'min', value: 0},
        {type: 'max', value: 10},
        {type: 'step', value: 1}
      ],
      rules: []
    }
  },
  {
    name: 'min',
    action: (definition, [min]) => definition.validation[0].value = min
  },
  {
    name: 'max',
    action: (definition, [max]) => definition.validation[1].value = max
  },
  {
    name: 'step',
    action: (definition, [step]) => definition.validation[2].value = step
  }
)


// ****** String

const fString = (...additionalMethods) => createBuilder(
  {
    type: 'String',
    definition: {
      prompt: '',
      model: '',
      required: false,
      display: '',
      rules: []
    }
  },
  ...[...ruleMethods, ...displayMethods, optionalMethod, ...additionalMethods]
)
const string = () => fString()()


// ****** Text

const fText = (additionalMethods = []) => createBuilder(
  {
    type: 'Text',
    definition: {
      model: '',
      prompt: '',
      display: '',
      required: false,
      rules: []
    }
  },
  ...[...displayMethods, optionalMethod, ...additionalMethods]
)
const text = () => fText()()


export {
  LOOKUP_STATUS_VALUES,

  createBuilder,

  fCurrency,
  fEmail,
  fPhone,
  fPhoneAusMobile,
  fRadioButtons,
  fSelect,
  fSlider,
  fString,
  fText,
  radioButtons,
  select,
  string,
  text,

  container,

  helpContent,

  ruleRender,
  ruleSet,
  ruleValidation,
  and,
  or,
  not,
  conjunction,
  fromModel,
  isDefined,
  isModelDefined,

  resolveChoices
}
