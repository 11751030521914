import isDefined from '../../utils/isDefined'
import valueOrDefault from '../../utils/valueOrDefault'

class ApplicationSettingsService {
  /*@ngInject*/
  constructor(api, $firebaseObject, loggerService) {
    const logger = loggerService.makeLogger().enableNewRelic(true)

    const FIREBASE_URL_PATTERN = /^https:\/\/.*\.firebaseio\.com/i
    const linkId = 'applicationSettings'

    this.eventHandlers = new Map()
    const processHandlers = () => {
      this.eventHandlers.forEach((value, key, map) => {
        value()
      })
    }

    this.init = () => {
      api.linkFor(linkId).then((link) => {
        if (link && link.href && !link.href.match(FIREBASE_URL_PATTERN)) {
          const message = 'Current Firebase libraries require: https://<YOUR FIREBASE>.firebaseio.com'
          logger.error(message)
          throw new Error(message)
        }

        this._applicationSettingFirebaseObject = $firebaseObject(new Firebase(link.href))
        this._applicationSettingFirebaseObject.$loaded().then(() => {
          processHandlers()
          this._applicationSettingFirebaseObject.$watch(() => {
              processHandlers()
            }
          )
        })
      }).catch(() => {
        const message = `Unable to retrieve the API link for '${linkId}'.`
        logger.error(message)
      })
    }
  }

  setting(settingName) {
    const defaultValue = false
    return isDefined(this._applicationSettingFirebaseObject) ? valueOrDefault(this._applicationSettingFirebaseObject[settingName], defaultValue) : defaultValue
  }

  addCallBack(key, lambda) {
    this.eventHandlers.set(key, lambda)
  }

  removeCallBack(key) {
    this.eventHandlers.delete(key)
  }
}

export default ApplicationSettingsService

