import assert from '../../../../utils/assert'
import resolveProperty from '../../../../utils/resolveProperty'
import valueOrDefault from '../../../../utils/valueOrDefault'
import isDefined from '../../../../utils/isDefined'

const isArray = (obj) => obj && Array.isArray(obj)
const copyArray = (candidateArray) => isArray(candidateArray) ? candidateArray.map(elem => elem) : []

// FIXME : Using reduce because Object.fromEntries is not part of the Babel presets we have loaded.
const copyOptionalModels = (optionalModels) => optionalModels ? Object.entries(optionalModels).reduce((om, [key, value]) => {
  om[key] = value
  return om
}, {}) : {}

// TODO : Second level widget definition validation - not implemented, yet.
// const baseAttributes = {
//   'classes': {type: 'string'},
//   'content': {type: 'string'},
//   'dialog': {type: 'object'},
//   'display': {type: 'enum', values: ['read-only', 'none']},
//   'config': {type: 'object'},
//   'model': {type: 'string'},
//   'optionalModels': {type: 'object'},
//   'preamble': {type: 'string'},
//   'prompt': {type: 'string'},
//   'required': {type: 'boolean'},
//   'rules': {type: 'array'},
//   'validation': {type: 'array'},
//   'widgets': {type: 'array'}
// }
const widgetSyntax = {
  'ABN': {requiredAttributes: new Set(['model', 'prompt']), optionalAttributes: new Set(['optionalModels', 'required', 'validEntityTypes'])},
  'ABN/ACN': {requiredAttributes: new Set(['model', 'prompt']), optionalAttributes: new Set(['optionalModels', 'required', 'validEntityTypes'])},
  'Address': {requiredAttributes: new Set(['model', 'prompt']), optionalAttributes: new Set(['required'])},
  'BorrowerType': {requiredAttributes: new Set(['model', 'prompt']), optionalAttributes: new Set(['display', 'required', 'rules'])},
  'ElectronicBankStatements': {requiredAttributes: new Set(['dialog', 'model', 'preamble', 'widgets']), optionalAttributes: new Set([])},
  'Container': {requiredAttributes: new Set(['widgets']), optionalAttributes: new Set(['prompt', 'classes', 'rules'])},
  'Currency': {requiredAttributes: new Set(['model', 'prompt']), optionalAttributes: new Set(['decimals', 'display', 'required', 'rules', 'validation'])},
  'Date': {requiredAttributes: new Set(['model', 'prompt']), optionalAttributes: new Set(['required'])},
  'Email': {requiredAttributes: new Set(['model', 'prompt']), optionalAttributes: new Set(['required'])},
  'FileUpload': {requiredAttributes: new Set(['config', 'model', 'prompt']), optionalAttributes: new Set(['required', 'rules'])},
  'Info': {requiredAttributes: new Set(['content']), optionalAttributes: new Set(['classes', 'prompt'])},
  'HelpContent': {requiredAttributes: new Set(['contentId']), optionalAttributes: new Set(['classes', 'rules'])},
  'LoanTypeAdmin': {requiredAttributes: new Set(['model', 'prompt']), optionalAttributes: new Set(['display', 'required', 'rules'])},
  'LoanType': {requiredAttributes: new Set(['model', 'prompt']), optionalAttributes: new Set(['display', 'required', 'rules'])},
  'Slider': {requiredAttributes: new Set(['model', 'prompt']), optionalAttributes: new Set(['display', 'required', 'rules', 'validation'])},
  'Phone': {requiredAttributes: new Set(['model', 'prompt']), optionalAttributes: new Set(['required'])},
  'PhoneAusMobile': {requiredAttributes: new Set(['model', 'prompt']), optionalAttributes: new Set(['required'])},
  'PipedriveEmail': {requiredAttributes: new Set(['model', 'prompt']), optionalAttributes: new Set(['optionalModels', 'required'])},
  'LoanTerm': {requiredAttributes: new Set(['model', 'prompt']), optionalAttributes: new Set(['required'])},
  'RadioButtons': {requiredAttributes: new Set(['model', 'prompt', 'radioButtons']), optionalAttributes: new Set(['display', 'required', 'rules', 'layout', 'defaultValue'])},
  'Select': {requiredAttributes: new Set(['model', 'prompt', 'choices']), optionalAttributes: new Set(['display', 'required', 'rules'])},
  'String': {requiredAttributes: new Set(['model', 'prompt']), optionalAttributes: new Set(['display', 'required', 'rules'])},
  'Text': {requiredAttributes: new Set(['model', 'prompt']), optionalAttributes: new Set(['display', 'required', 'rules'])},
  'TFN': {requiredAttributes: new Set(['model', 'prompt']), optionalAttributes: new Set(['display', 'required', 'rules'])},
}
const widgetTypes = Object.getOwnPropertyNames(widgetSyntax)

const validateSyntax = (type, definition) => {
  assert(widgetTypes.includes(type), `Unknown widget type: '${type}'.`)
  const widgetAttributes = new Set(Object.getOwnPropertyNames(definition))
  widgetSyntax[type].requiredAttributes.forEach((requiredAttribute => {
    assert(widgetAttributes.has(requiredAttribute), `Widget of type '${type}' missing required attribute '${requiredAttribute}'.`)
  }))
  const validAttributes = new Set([...widgetSyntax[type].requiredAttributes, ...widgetSyntax[type].optionalAttributes])
  widgetAttributes.forEach(attribute => {
    assert(validAttributes.has(attribute), `Widget of type '${type}' should not have an attribute of '${attribute}'`)
  })
}


const parseWidget = (formWidget, formCache, parse) => {
  assert(formWidget.hasOwnProperty('type') && formWidget.type !== undefined, () => `Widget type must be specified. ${JSON.stringify(formWidget)}`)
  assert(formWidget.hasOwnProperty('definition') && typeof formWidget.definition === 'object' && !Array.isArray(formWidget.definition),
    () => `Widget definition must be defined. ${JSON.stringify(formWidget)}`)
  validateSyntax(formWidget.type, formWidget.definition)

  const definition = formWidget.definition

  const classes = valueOrDefault(definition.classes, '')
  const content = valueOrDefault(definition.content, '')
  const contentId = valueOrDefault(definition.contentId, '')
  const dialog = {
    heading: valueOrDefault(resolveProperty(definition, 'dialog', 'heading'), ''),
    instructions: valueOrDefault(resolveProperty(definition, 'dialog', 'instructions'), '')
  }
  const display = valueOrDefault(definition.display, '')
  const fileUploadConfig = (() => {
    const config = {
      fileUploads: copyArray(resolveProperty(definition, 'config', 'fileUploads'))
    }
    const commentConfig = resolveProperty(definition, 'config', 'comments')
    if (isDefined(commentConfig)) {
      config.comments = {
        prompt: valueOrDefault(resolveProperty(commentConfig, 'prompt'), 'Comments'),
        placeholder: valueOrDefault(resolveProperty(commentConfig, 'placeholder'), 'Please add any comments here.')
      }
    }
     return config
  })()
  const mappedCache = formCache ? formCache : new Map()
  const modelFieldName = definition.model
  const optionalModels = copyOptionalModels(definition.optionalModels)
  const layout = valueOrDefault(definition.layout, formWidget.type === 'RadioButtons' ? 'inline-radio-buttons' : '')
  const preamble = valueOrDefault(definition.preamble, '')
  const prompt = valueOrDefault(definition.prompt, '')
  const required = valueOrDefault(definition.required, false)
  const rules = valueOrDefault(definition.rules, [])
  const type = formWidget.type
  const validations = copyArray(definition.validation)
  const widgets = copyArray(definition.widgets).map(widgetDefinition => parseWidget(widgetDefinition, new Map(), parse))
  const sourceWidgets = valueOrDefault(definition.widgets, [])
  const validEntityTypes = valueOrDefault(definition.validEntityTypes, [])
  const radioButtons = valueOrDefault(definition.radioButtons, [])
  const choices = valueOrDefault(definition.choices, [])
  const decimals = valueOrDefault(definition.decimals, 0)

  const mutableState = new Map()
  let _formModel = undefined

  return {
    get classes() {
      return classes
    },
    get content() {
      return content
    },
    get contentId() {
      return contentId
    },
    get decimals() {
      return decimals
    },
    get dialogHeading() {
      return dialog.heading
    },
    get dialogInstructions() {
      return dialog.instructions
    },
    get display() {
      return display
    },
    get fileUploadConfig() {
      return fileUploadConfig
    },
    get formCache() {
      return mappedCache
    },
    get layout() {
      return layout
    },
    get modelFieldName() {
      return modelFieldName
    },
    get optionalModels() {
      return optionalModels
    },
    get preamble() {
      return preamble
    },
    get prompt() {
      return prompt
    },
    get required() {
      return required
    },
    get rules() {
      return rules
    },
    get sourceWidgets() {
      return sourceWidgets
    },
    get type() {
      return type
    },
    get validations() {
      return validations
    },
    get validEntityTypes() {
      return validEntityTypes
    },
    get widgets() {
      return widgets
    },
    get radioButtons() {
      return radioButtons
    },
    get choices() {
      return choices
    },
    get render() {
      const renderState = mutableState.get('render')
      return renderState === undefined ? true : renderState
    },
    get isValid() {
      const validState = mutableState.get('validation')
      return validState === undefined ? true : validState.valid
    },
    get validationMessages() {
      const validState = mutableState.get('validation')
      return validState === undefined ? '' : validState.messages.join(' ')
    },

    set formModel(model) {
      // FYI: This is activated by adding the rule-validate directive (attribute) to the form input element.
      //      Without adding this attribute to your input, then your dynamic validation rules will not show any validation error messages.
      _formModel = model
    },

    processRules(evaluationContext, models) {
      mutableState.set('validation', {valid: true, messages: []})
      return rules.reduce((anyChanged, rule) => {
        const preRuleState = mutableState.get(rule.type)
        const evaluation = parse(rule.rule, evaluationContext)
        switch (rule.type) {
          case 'set':
            models[modelFieldName] = evaluation
            mutableState.set(rule.type, evaluation)
            break
          case 'validation':
            const result = mutableState.get(rule.type)
            result.valid = result.valid && !!evaluation
            if (!evaluation) {
              result.messages.push(rule.message)
            }
            if (_formModel) {
              _formModel.$setValidity('dynval', result.valid)
            }
            mutableState.set(rule.type, result)
            break
          default:
            mutableState.set(rule.type, evaluation)
            break
        }
        if (rule.type === 'set') {
          models[modelFieldName] = evaluation
        }
        return anyChanged || mutableState.get(rule.type) !== preRuleState
      }, false)
    }
  }
}


const extractWidgetsWithRules = (widgets) => {
  const extractedWidgets = []
  const accumulateWidgets = (accumulatedWidgets, widgets) => {
    if (widgets && Array.isArray(widgets) && widgets.length > 0) {
      widgets.forEach((widget) => {
        if (widget.rules.length > 0) {
          extractedWidgets.push(widget)
        }
        accumulateWidgets(extractedWidgets, widget.widgets)
      })
    }
  }
  accumulateWidgets(extractedWidgets, widgets)
  return extractedWidgets
}


export {parseWidget, extractWidgetsWithRules}
