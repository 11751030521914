import sortComparator from '../../../../utils/sortComparator'
import resolveProperty from '../../../../utils/resolveProperty'
import valueOrDefault from '../../../../utils/valueOrDefault'
import makeObservableAmount from '../../../../utils/makeObservableAmount'

import moment from 'moment'
import Big from 'big.js'

const BIG_ZERO = new Big('0.0000')

const KEY_BAR_COLOUR_P2P = '--summary-bar-colour-p2p'
const KEY_BAR_COLOUR_RESERVED = '--summary-bar-colour-reserved'
const KEY_BAR_COLOUR_AVAILABLE = '--summary-bar-colour-available'
const BAR_COLOUR_DEFAULT = '#ffaabb'

const initialiseToZero = () => new Big('0.0000')
const isZero = (value) => value.cmp(BIG_ZERO) === 0

const makeInvestorLoadingStatus = () => {
  const loadStatuses = {
    investor: false,
    investmentProductParts: false,
    earningSummary: false,
    statisticsCalculated: false,
    investmentEarningSummaryHistory: false
  }
  return {
    loadedInvestor: () => loadStatuses.investor = true,
    loadedInvestmentProductParts: () => loadStatuses.investmentProductParts = true,
    loadedEarningSummary: () => loadStatuses.earningSummary = true,
    loadedInvestmentEarningSummaryHistory: () => loadStatuses.investmentEarningSummaryHistory = true,
    calculatedStatistics: () => loadStatuses.statisticsCalculated = true,
    get loaded() {
      return loadStatuses.investor && loadStatuses.investmentProductParts
    },
    get areStatisticsCalculated() {
      return loadStatuses.statisticsCalculated
    }
  }
}

const initBarColours = (customPropertiesService) => {
  return [KEY_BAR_COLOUR_P2P, KEY_BAR_COLOUR_RESERVED, KEY_BAR_COLOUR_AVAILABLE].reduce((mapping, key) => {
    const colour = customPropertiesService.getProperty(key).trim()
    mapping.set(key, colour !== '' ? colour : BAR_COLOUR_DEFAULT)
    return mapping
  }, new Map())
}

const makeOverviewRow = (type, label, amount, colour, periodEarnings) => {
  const row = {type, label, amount}
  if (colour) {
    row.ratio = {colour}
  }
  if (periodEarnings) {
    row.periodEarnings = periodEarnings
  }
  return row
}

class InvestorProductsSummaryController {
  /*@ngInject*/
  constructor($window, $filter,
              entityContextService, emailVerificationService,
              brandingService, tenantInformationService, customPropertiesService,
              investmentEarningSummariesRepository) {
    this.name = 'investor-products-summary'
    this.emailVerificationService = emailVerificationService
    this.$filter = $filter
    this.$window = $window

    const barColours = initBarColours(customPropertiesService)

    this.lastMonth = moment().subtract(1, 'month').local().locale('en')
    this.investorLoading = makeInvestorLoadingStatus()
    this.emailVerificationService.checkIfEmailVerified()

    // ****** hierarchy of totals

    const availableFunds = makeObservableAmount()
    const reservedFunds = makeObservableAmount()

    const p2pTotalInvested = makeObservableAmount()
    const p2pPeriodEarnings = makeObservableAmount()
    const p2pTotalEarnings = makeObservableAmount()

    const ipTotalInvested = makeObservableAmount()
    const ipPeriodEarnings = makeObservableAmount()
    const ipTotalEarnings = makeObservableAmount()

    const totalInvested = makeObservableAmount().addObservables(p2pTotalInvested, ipTotalInvested)
    const totalEarnings = makeObservableAmount().addObservables(p2pTotalEarnings, ipTotalEarnings)

    const totalRowAmount = makeObservableAmount().addObservables(availableFunds, reservedFunds, totalInvested)
    const totalRowPeriodEarnings = makeObservableAmount().addObservables(p2pPeriodEarnings, ipPeriodEarnings)

    // ****** Overview rows

    // FIXME : Fake P2P period interest rate.
    this.overviewP2P = makeOverviewRow('investment', 'P2P', p2pTotalInvested, barColours.get(KEY_BAR_COLOUR_P2P), p2pPeriodEarnings)
    this.overviewReserved = makeOverviewRow('cash', 'Reserved', reservedFunds, barColours.get(KEY_BAR_COLOUR_RESERVED))
    this.overviewAvailable = makeOverviewRow('cash', 'Available funds', availableFunds, barColours.get(KEY_BAR_COLOUR_AVAILABLE))
    this.overviewTotal = makeOverviewRow('total', 'TOTAL', totalRowAmount, undefined, totalRowPeriodEarnings, initialiseToZero())

    this.reservedRows = []
    this.p2pRows = []
    this.ipRows = []

    // ****** Summary information

    const that = this
    const overviews = []
    this.summary = {
      totals: {
        total: totalRowAmount,
        invested: totalInvested,
        interest: totalEarnings
      },
      get overview() {
        if (that.investorLoading.loaded && !that.investorLoading.areStatisticsCalculated) {
          overviews.splice(overviews.length, 0, ...that.ipRows, ...that.p2pRows, ...that.reservedRows, that.overviewAvailable, that.overviewTotal)

          const statistics = overviews
            .filter(overview => overview.type !== 'total')
            .reduce((stats, overview) => {
              stats.largestAmount = overview.amount.amount.cmp(stats.largestAmount) === 1 ? overview.amount.amount : stats.largestAmount
              return stats
            }, {largestAmount: initialiseToZero()})
          that.largestAmount = statistics.largestAmount

          that.investorLoading.calculatedStatistics()
        }
        return overviews
      }
    }

    this.largestAmount = undefined
    this.calcSize = (amount) => {
      return this.largestAmount && !isZero(this.largestAmount) ? (amount.times(100).div(this.largestAmount)).toFixed(0) : 0
    }

    this.products = {}
    tenantInformationService.getElement('products').then((products) => this.products = products)

    // ****** Initialisation

    this.$onInit = () => {
      entityContextService.currentInvestor().then((investor) => {
        this.investor = investor
        reservedFunds.amount = this.investor.reservedFunds
        p2pTotalInvested.amount = this.investor.p2pInvestedFunds
        availableFunds.amount = this.investor.availableFunds

        // Process reserved fund.
        this.reservedRows = !isZero(this.overviewReserved.amount.amount) ? [this.overviewReserved] : []

        // Process earning summary.
        this.investor.promise('investmentEarningSummary')
          .then(earningSummary => {
            p2pTotalEarnings.amount = earningSummary.p2p.interestReceived
            ipTotalEarnings.amount = earningSummary.ip.interestReceived
          })
          .catch((error) => this._reportError(error))
          .finally(() => this.investorLoading.loadedEarningSummary())

        // Process earning summary history
        const formatDate = (theDate) => moment(theDate).locale('en').format('YYYY-MM-DD')
        const fromDate = moment().startOf('day').subtract(1, 'months').toDate()
        const toDate = moment().startOf('day').subtract(1, 'days').toDate()
        const query = {id: this.investor.id, fromDate: formatDate(fromDate), toDate: formatDate(toDate)}
        investmentEarningSummariesRepository.where(query)
          .then((summaryHistory) => {
            p2pPeriodEarnings.plus(summaryHistory.p2p.interestReceived)
          })
          .catch((error) => this._reportError(error))
          .finally(() => this.investorLoading.loadedInvestmentEarningSummaryHistory())

        // Process investment products.
        const ipById = new Map()
        this.investor.promise('investmentProductParts')
          .then(investmentProductParts => {
            investmentProductParts.forEach((part) => {
              const investmentProduct = ipById.get(part.investmentProductId)
              if (['active', 'maturity_rollover_pending', 'maturity_redeem_pending'].includes(part.status)) {
                if (!investmentProduct) {
                  const investmentProductPartRow = {
                    product: {
                      name: part.investmentProductSummary.name,
                      brandingId: part.investmentProductSummary.brandingId,
                      commitmentPeriod: part.investmentProductSummary.commitmentPeriod,
                      endDate: part.investmentProductSummary.endDate
                    },
                    amount: new Big(part.currentValue),
                    periodEarnings: new Big(valueOrDefault(resolveProperty(part, 'lastDisbursementSummary', 'amount'), '0.0000')),
                  }
                  ipById.set(part.investmentProductId, investmentProductPartRow)
                } else {
                  investmentProduct.amount = investmentProduct.amount.plus(part.currentValue)
                  investmentProduct.periodEarnings = investmentProduct.periodEarnings.plus(valueOrDefault(resolveProperty(part, 'lastDisbursementSummary', 'amount'), '0.0000'))
                }
              }
            })
          })
          .catch((error) => this._reportError(error))
          .finally(() => {
            this.ipRows = [...ipById.values()]
              .sort(sortComparator(
                (investmentProduct1, investmentProduct2) => new Big(investmentProduct1.product.commitmentPeriod).cmp(investmentProduct2.product.commitmentPeriod),
                (investmentProduct1, investmentProduct2) => moment(investmentProduct1.product.endDate).diff(moment(investmentProduct2.product.endDate)),
                (investmentProduct1, investmentProduct2) => investmentProduct1.product.name.localeCompare(investmentProduct2.product.name)
              ))
              .map((investmentProduct) => {
                const row = makeOverviewRow(
                  'investment',
                  investmentProduct.product.name,
                  makeObservableAmount(investmentProduct.amount),
                  brandingService.getColourForBrandingIdentifier(investmentProduct.product.brandingId),
                  makeObservableAmount(investmentProduct.periodEarnings)
                )
                ipTotalInvested.addObservables(row.amount)
                ipPeriodEarnings.addObservables(row.periodEarnings)
                return row
              })
            this.investorLoading.loadedInvestmentProductParts()
          })

        this.p2pRows = !isZero(this.overviewP2P.amount.amount) ? [this.overviewP2P] : []
      })
        .catch((error) => this._reportError(error))
        .finally(() => this.investorLoading.loadedInvestor())
    }
  }

  // ****** Public and shared methods

  get emailVerified() {
    return this.emailVerificationService.emailVerified()
  }

  resendVerificationEmail() {
    this.emailVerificationService.resendVerificationEmail()
  }

  // ****** Private methods

  _reportError(error) {
    if (this.$window.NREUM) {
      this.$window.NREUM.noticeError(new Error(error))
    }
    return error
  }
}

export default InvestorProductsSummaryController
