import {
  borrowerName,
  borrowerPhone,
  borrowerEmail,
  businessFullComposite,
  contactType,
  fileUploadBuilder,
  loanAmount,
  loanPurposeAdmin,
  propertyOwner,
  referrerNotes
} from '../dynamicEnquiryFormHelpers'

import {
  container,
  helpContent,
  radioButtons,
  ruleRender,
  ruleSet
} from '../../../dynamicForm/helpers/dynamicFormHelpers'

const conjunction = (rel) => (...predicates) => `(${rel} ` + predicates.join(' ') + ')'

const and = conjunction('and')

const isLoanAmount = '(isDefined (fromModel "loanAmount"))'
const isReferrerNotes = '(isDefined (fromModel "referrerNotes"))'
const isTrailBookLoanTarget = '(isDefined (fromModel "trailBookLoanTarget"))'
const isPropertyOwner = '(isDefined (fromModel "propertyOwner"))'
const isBusinessEntity = '(and (isDefined (fromModel "businessABN")) (isDefined (fromModel "businessName")))'

const trailBookAdminEnquiryForm = {
  title: 'Admin trail book loan enquiry',
  model: 'trail-book-enquiry-application',
  actionLabel: 'Submit enquiry',
  widgets: [
    container()
      .prompt('Trail book enquiry')
      .classes('border')
      .widgets(
        loanPurposeAdmin().required(true).display('none').rules(ruleSet('(or "trailBook")')).build(),
        contactType().admin().build(),
        helpContent().contentId('enquiry-form-trail-book-application-main').build(),
        container()
          .boxed(5)
          .widgets(
            loanAmount().prompt('Preferred trail book loan amount?').build(),
            radioButtons()
              .required(true)
              .model('trailBookLoanTarget')
              .prompt('Type of trail book?')
              .button('Existing trail book', 'existing')
              .button('Acquiring new trail book', 'acquisition')
              .rules(ruleRender(isLoanAmount))
              .build(),
          ).build(),
        container()
          .boxed(5)
          .rules(ruleRender(isTrailBookLoanTarget))
          .widgets(
            ...businessFullComposite(),
            referrerNotes().required(true).prompt('Describe your business background and history, your aggregator/franchisor, and the reason for the loan').rules(ruleRender(isBusinessEntity)).build()
          ).build(),
        container()
          .boxed(5)
          .prompt('Your details')
          .rules(ruleRender(isReferrerNotes))
          .widgets(
            helpContent().contentId('enquiry-form-trail-book-borrower-contact-direct').build(),
            borrowerName().prompt('Your name').build(),
            borrowerPhone().prompt('Your phone').build(),
            borrowerEmail().prompt('Your email').build(),
            radioButtons()
              .model('hasGuarantor')
              .prompt('Is there a guarantor, other than the borrower, for this loan?')
              .button('Guarantor', 'yes')
              .button('Not required', 'no')
              .build(),
            container()
              .boxed(5)
              .prompt('Guarantor details')
              .rules(ruleRender(and('(isDefined (fromModel "hasGuarantor"))', '(eq (fromModel "hasGuarantor") "yes")')))
              .widgets(
                borrowerName().model('guarantorName').prompt('Guarantor\'s name').build(),
                borrowerPhone().model('guarantorPhone').prompt('Guarantor\'s mobile').required(false).build(),
                borrowerEmail().model('guarantorEmail').prompt('Guarantor\'s email address').build()
              ).build(),
            propertyOwner().prompt('Does the person providing the guarantee on the loan own property?').required(true).build(),
          ).build(),
        container()
          .boxed(5)
          .prompt('Documents')
          .rules(ruleRender(isPropertyOwner))
          .widgets(
            fileUploadBuilder()
              .fileGroup('accountantPreparedFinancials', 'Accountant prepared financials')
              .fileGroup('accountantPreparedFinancials', 'YTD management accounts')
              .fileGroup('atoICA', 'ATO portal report (Integrated Client Account)')
              .fileGroup('trailIncomeSummary', 'Trail income summary reports (last 3 months)')
              .fileGroup('contractOfSale', 'Contract of sale (if applicable)')
              .fileGroup('personalStatementOfPosition', 'Personal statement of position')
              .fileGroup('aggregatorFranchisorAgreement', 'Aggregator / franchisor agreement')
              .fileGroup('councilRates', 'Council rates notices, if property backed')
              .build())
          .build())
      .build()
  ]
}


export default trailBookAdminEnquiryForm
