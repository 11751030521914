import angular from 'angular'

import homeModule from './home/home'
import marketingContentModule from './marketingContent/marketingContent'
import faqsModule from './faqs/faqs'
import mediaModule from './media/media'
import unfurlCardModule from './unfurlCard/unfurlCard'

export default angular.module('app.pages.marketing', [
  homeModule.name,
  faqsModule.name,
  mediaModule.name,
  unfurlCardModule.name,
  marketingContentModule.name
]).config(['$stateProvider', $stateProvider => {
  $stateProvider
    .state('marketingContent', { // This is the original CMS content driven URL.
      url: '/content/:id',
      template: '<marketing-content/>',
      data: {
        appClasses: ['marketing-content', 'hero'],
        contentPrefix: 'marketing-content'
      }
    })
    .state('productContent', { // This is a replication of marketingContent to give a 'product' URL.
      url: '/products/:id',
      template: '<marketing-content/>',
      data: {
        appClasses: ['marketing-content', 'hero'],
        contentPrefix: 'product-content'
      }
    })
    .state('partnerContent', { // This is a replication of marketingContent to give a 'product' URL.
      url: '/partners/:id',
      template: '<marketing-content/>',
      data: {
        appClasses: ['marketing-content', 'hero'],
        contentPrefix: 'partner-content'
      }
    })
    .state('investorContent', { // This is a replication of marketingContent to give a 'product' URL.
      url: '/investors/:id',
      template: '<marketing-content/>',
      data: {
        appClasses: ['marketing-content', 'hero'],
        contentPrefix: 'investor-content'
      }
    })
}])
