import valueOrDefault from '../../../../utils/valueOrDefault'
import isDefined from '../../../../utils/isDefined'

const assetsCache = new Map()

class InvestmentProductPartsController {
  /*@ngInject*/
  constructor($rootScope, $q, $mdDialog, entityContextService, investmentProductsRepository, iconsService, featureTogglesService, loggerService) {
    this.name = 'investment-product-parts'
    this.investmentProductsRepository = investmentProductsRepository
    this.iconsService = iconsService
    this.featureTogglesService = featureTogglesService
    this.loggerService = loggerService

    this.logger = loggerService.makeLogger().enableNewRelic(true)

    this.order = '-startDate'

    this._investmentProductPartStatusTranslationMap = new Map([
      ['pending', {label: 'Pending', toolTip: 'Pending acceptance', iconName: 'icon-2022-ipp-status-pending'}],
      ['active', {label: 'Active', toolTip: 'Active investment', iconName: 'icon-2022-ipp-status-active'}],
      ['maturity_rollover_pending', {label: 'Maturity', toolTip: 'Maturity rollover pending', iconName: 'icon-2022-ipp-status-maturity-rollover-pending'}],
      ['maturity_redeem_pending', {label: 'Maturity', toolTip: 'Maturity redeem pending', iconName: 'icon-2022-ipp-status-maturity-redeem-pending'}],
      ['closed', {label: 'Closed', toolTip: 'Closed', iconName: 'icon-2022-ipp-status-closed'}],
    ])

    const detailsToggles = new Map()
    this.reload = () => {
      this.loading = true
      detailsToggles.clear()
      this.loadingPromise = this.investor.reloadResource('investmentProductParts')
        .then(investmentProductParts => this.investmentProductParts = investmentProductParts)
        .finally(() => this.loading = false)
    }
    this.$onInit = () => {
      this.loading = true
      this.investmentProductsRepository.where({scope: 'active'}).then(response => this.investmentProducts = response.investmentProducts)
      this.loadingPromise = entityContextService.currentInvestor().then((investor) => {
        this.investor = investor
        this.loadingPromise = investor.promise('investmentProductParts')
          .then(investmentProductParts => {
            this.investmentProductParts = investmentProductParts
          })
          .finally(() => this.loading = false)
      })
    }

    this.toggleDetailsRow = (investmentProductPart) => {
      if (detailsToggles.has(investmentProductPart.id) ? !detailsToggles.get(investmentProductPart.id) : true) {
        investmentProductPart.reload().then(() => detailsToggles.set(investmentProductPart.id, true))
      } else {
        detailsToggles.set(investmentProductPart.id, false)
      }
    }

    this.displayDetailsRow = (investmentProductPart) => {
      return detailsToggles.has(investmentProductPart.id) && detailsToggles.get(investmentProductPart.id)
    }

    this.showCreateInvestmentProductPledgeRequestDialog = (investmentProduct, $event) => {
      const scope = $rootScope.$new()
      scope.investor = this.investor
      scope.investmentProduct = investmentProduct
      scope.investmentProductParts = this.investmentProductParts.filter((entry) => entry.investmentProductId === investmentProduct.id && entry.status === 'active')
      scope.completion = $q.defer()
      scope.completion.promise
        .then(() => $rootScope.$broadcast('InvestmentProductPledgeRequestCreated'))
        .finally(() => {
          return $mdDialog.hide()
        })
      $mdDialog.show({
        parent: angular.element(document.body),
        targetEvent: $event,
        clickOutsideToClose: true,
        template: `
        <md-dialog class="dialog-create-pledge" flex-xs="85" flex-sm="75" flex-md="50" flex-lg="35" flex-gt-lg="25">
          <md-dialog-content>
            <create-investment-product-pledge-request investor='investor' investment-product='investmentProduct' investment-product-parts='investmentProductParts' completion='completion'/>
          </md-dialog-content>
        </md-dialog>`,
        scope
      })
    }
  }

  stopPropagation($event) {
    $event.stopPropagation()
    return false
  }

  displayStatus(investmentProductPartStatus) {
    return valueOrDefault(this._investmentProductPartStatusTranslationMap.get(investmentProductPartStatus),
      {label: investmentProductPartStatus}).label
  }

  displayStatusTooltip(investmentProductPartStatus) {
    return valueOrDefault(this._investmentProductPartStatusTranslationMap.get(investmentProductPartStatus),
      {toolTip: investmentProductPartStatus}).toolTip
  }

  iconStatusFor(investmentProductPartStatus) {
    return assetsCache.get(investmentProductPartStatus) ||
      (() => {
        const statusMetadata = this._investmentProductPartStatusTranslationMap.get(investmentProductPartStatus)
        if (!statusMetadata) {
          return {status: 'error'}
        }
        const candidateAsset = this.iconsService.getByName(statusMetadata.iconName)
        assetsCache.set(investmentProductPartStatus, candidateAsset)
        return candidateAsset
      })()
  }

  iconStyleFor(investmentProductPartStatus) {
    const asset = this.iconStatusFor(investmentProductPartStatus)
    return {
      'height': '25px',
      'mask-image': `url(${asset.url})`
    }
  }

  toggleAutoReinvest(investmentProductPart) {
    investmentProductPart.save().catch(e => this.logger.error(`Error: Saving the autoReinvest change to the Investment Product Loan part failed: '${JSON.stringify(e)}'.`))
  }

  isProductType(investmentProductPart, candidateType) {
    return investmentProductPart.type === candidateType
  }

  showIconMaturityInstruction(investmentProductPart) {
    return isDefined(investmentProductPart) &&
      investmentProductPart.status === 'active' &&
      investmentProductPart.type === 'standard'
  }

  iconMaturityInstruction(investmentProductPart) {
    return 'edit'
  }

  showIconAutoReinvest(investmentProductPart) {
    return isDefined(investmentProductPart) && investmentProductPart.status === 'active'
  }

  maturityInstructionDisplay(investmentProductPart) {
    if (investmentProductPart.maturityInstruction === 'redeem') {
      return 'Redeem'
    } else if (investmentProductPart.maturityInstruction === 'rollover') {
      return investmentProductPart.maturityRolloverInvestmentProductName
    } else {
      this.logger.error(`Unknown investment product part maturity instruction: '${investmentProductPart.maturityInstruction}'`)
      return `(?? ${investmentProductPart.maturityInstruction})`
    }
  }
}

export default InvestmentProductPartsController
