// Components for re-use in form definitions.
// With specific overrides for loan enquiry forms - to get the model names right.

import assert from '../../../utils/assert'
import {
  createBuilder,
  fCurrency,
  fEmail,
  fPhoneAusMobile,
  fSlider,
  fString,
  fText,
  radioButtons,
  ruleSet,
  ruleValidation,
  and,
  isModelDefined,
  fromModel,
  conjunction,
  select
} from '../../dynamicForm/helpers/dynamicFormHelpers'


// ****** assetDescription

const assetDescriptionModel = 'assetDescription'
const assetDescription = () => fText()()
  .model(assetDescriptionModel)
  .prompt('Describe the asset to be financed, including its condition')
  .required(true)
const isAssetDescription = isModelDefined(assetDescriptionModel)


// ****** assetNewOrUsed

const assetNewOrUsedModel = 'assetNewOrUsed'
const assetNewOrUsed = () => radioButtons()
  .required(true)
  .model(assetNewOrUsedModel)
  .prompt('New or used vehicle?')
  .button('New', 'new')
  .button('Used', 'used')
const isAssetNewOrUsed = isModelDefined(assetNewOrUsedModel)


// ****** assetRideShareEV

const assetRideShareElectricVehicleModel = 'assetRideShareElectricVehicle'
const assetRideShareElectricVehicle = () => radioButtons()
  .model(assetRideShareElectricVehicleModel)
  .prompt('Is this asset a ride-share electric vehicle?')
  .button('Ride-share electric vehicle', 'yes')
  .button('Other', 'no')
const isAssetRideShareElectricVegicle = isModelDefined(assetRideShareElectricVehicleModel)

const assetRideShareElectricVehicleFixed = () => fString(
  {
    name: 'yes',
    action: (definition) => definition.rules.push(ruleSet('"yes"'))
  },
  {
    name: 'no',
    action: (definition) => definition.rules.push(ruleSet('"no"'))
  })().model(assetRideShareElectricVehicleModel)
  .prompt('Is this asset a ride-share electric vehicle?')
  .hidden()
  .required(true)


// ****** borrowerEmail

const borrowerEmailModel = 'borrowerEmail'
const borrowerEmail = () => fEmail()()
  .prompt('Borrower\'s email address')
  .model(borrowerEmailModel)
  .required(true)
const isBorrowerEmail = isModelDefined(borrowerEmailModel)


// ****** borrowerName

const borrowerNameModel = 'borrowerName'
const borrowerName = () => fString()()
  .prompt('Borrower\'s name')
  .model(borrowerNameModel)
  .required(true)
const isBorrowerName = isModelDefined(borrowerNameModel)


// ****** borrowerPhone

const borrowerPhoneModel = 'borrowerPhone'
const borrowerPhone = () => fPhoneAusMobile()()
  .prompt('Borrower\'s  mobile number')
  .model(borrowerPhoneModel)
  .required(true)
const isBorrowerPhone = isModelDefined(borrowerPhoneModel)


// ****** businessABN

const businessABNModel = 'businessABN'
const businessABN = (required = true) =>
  ({
    type: 'ABN',
    definition: {
      prompt: 'Borrowing entity ABN?',
      required: required,
      model: businessABNModel,
      optionalModels:
        {
          name: ['businessName', 'businessABNName'],
          tradingName: ['businessTradingName'],
          state: 'businessAddressState',
          postcode: 'businessAddressPostcode',
          gstRegisteredSince: 'businessGstRegisteredSince',
          tradingSince: 'businessTradingSince',
          entityType: 'businessStructure',
          entityDescription: 'businessEntityDescription',
          statusCode: 'businessStatusCode'
        }
    }
  })
const isBusinessABN = isModelDefined(businessABNModel)

const businessNameModel = 'businessName'
const businessName = (required = false, display = 'read-only') =>
  ({
    type: 'String',
    definition: {
      model: businessNameModel,
      prompt: 'Borrowing entity business name',
      required: required,
      display: display,
      rules: [
        {
          type: 'render',
          rule: '(isDefined (fromModel "businessABN"))'
        }
      ]
    }
  })
const isBusinessName = isModelDefined(businessNameModel)

const businessABNNameModel = 'businessABNName'
const businessABNName = (required = false, display = 'none') =>
  ({
    type: 'String',
    definition: {
      model: businessABNNameModel,
      prompt: 'Registered business name',
      required: required,
      display: display
    }
  })
const isBusinessABNName = isModelDefined(businessABNNameModel)

const businessStructureModel = 'businessStructure'
const businessStructure = (required = false, display = 'none') =>
  ({
      type: 'String',
      definition: {
        model: businessStructureModel,
        prompt: 'Business entity type code',
        required: required,
        display: display
      }
    }
  )
const isBusinessStructure = isModelDefined(businessStructureModel)

const trusteeABNACNModel = 'trusteeABNACN'
const trusteeABNACN = (required = false) =>
  ({
    type: 'ABN/ACN',
    definition: {
      required: required,
      prompt: `${required ? 'T' : 'Optional: t'}rustee ABN/ACN?`,
      model: trusteeABNACNModel,
      validEntityTypes: ['PRV', 'PUB', 'OIE', 'APTY', 'APUB', 'LTDP'],
      optionalModels:
        {
          name: ['trusteeName', 'trusteeABNACNName'],
          tradingName: ['trusteeTradingName'],
          state: 'trusteeAddressState',
          postcode: 'trusteeAddressPostcode',
          gstRegisteredSince: 'trusteeGstRegisteredSince',
          tradingSince: 'trusteeTradingSince',
          entityType: 'trusteeStructure',
          entityDescription: 'trusteeEntityDescription',
          statusCode: 'trusteeStatusCode'
        }
    }
  })
const isTrusteeABNACN = isModelDefined(trusteeABNACNModel)

const trusteeNameModel = 'trusteeName'
const trusteeName = (required = false, display = 'read-only') =>
  ({
    type: 'String',
    definition: {
      required: required,
      model: trusteeNameModel,
      prompt: `Trustee name`,
      display: display,
      rules: [
        {
          type: 'render',
          rule: '(isDefined (fromModel "trusteeABNACN"))'
        }
      ]
    }
  })
const isTrusteeName = isModelDefined(trusteeNameModel)

const trusteeABNACNNameModel = 'trusteeABNACNName'
const trusteeABNACNName = (required = false, display = 'none') =>
  ({
    type: 'String',
    definition: {
      model: trusteeABNACNNameModel,
      prompt: 'Registered trustee name',
      required: required,
      display: display
    }
  })
const isTrusteeABNACNName = isModelDefined(trusteeABNACNNameModel)

const trusteeStructureModel = 'trusteeStructure'
const trusteeStructure = (required = false, display = 'none') =>
  ({
    type: 'String',
    definition: {
      model: trusteeStructureModel,
      prompt: 'Trustee entity type code',
      required: required,
      display: display
    }
  })
const isTrusteeStructure = isModelDefined(trusteeStructureModel)

const borrowerTypeModel = 'borrowerType'
const borrowerType = (required = false, display = 'none') =>
  ({
    type: 'BorrowerType',
    definition: {
      rules: [
        {
          type: 'set',
          rule:
            '(or ' +
            '    (and ' +
            '        (and ' +
            '            (isDefined (fromModel "businessStructure")) ' +
            '            (includes (fromModel "businessStructure") "CUT" "DIT" "DST" "DTT" "FPT" "FUT" "FXT" "HYT" "PQT") ' +
            '        ) ' +
            '        (or ' +
            '            (and ' +
            '                (isDefined (fromModel "trusteeABNACN")) ' +
            '                "trustCorporate" ' +
            '            ) ' +
            '            "trust" ' +
            '        ) ' +
            '    ) ' +
            '    (and ' +
            '        (isDefined (fromModel "businessStructure")) ' +
            '        (or ' +
            '            (and ' +
            '                (eq (fromModel "businessStructure") "IND") ' +
            '                "soleTrader" ' +
            '            ) ' +
            '            (and ' +
            '                (includes (fromModel "businessStructure") "PRV" "PUB" "OIE" "APTY" "APUB" "LTDP") ' +
            '                "company" ' +
            '            ) ' +
            '        ) ' +
            '    ) ' +
            '    "other" ' +
            ')'
        }
      ],
      model: borrowerTypeModel,
      prompt: 'Type of the borrowing entity?',
      required: required,
      display: display
    }
  })
const isBorrowerType = isModelDefined(borrowerTypeModel)

const businessFullComposite = () =>
  ([
      businessABN(),
      businessName(),
      businessABNName(),
      businessStructure(),
      {
        type: 'Container',
        definition: {
          rules: [
            {type: 'render', rule: '(and (isDefined (fromModel "businessStructure")) (includes (fromModel "businessStructure") "CUT" "DIT" "DST" "DTT" "FPT" "FUT" "FXT" "HYT" "PQT"))'}
          ],
          widgets: [
            trusteeABNACN(),
            trusteeName(),
            trusteeABNACNName(),
            trusteeStructure(),
          ]
        }
      },
      borrowerType()
    ]
  )


// ****** ContactType

const contactTypeModel = 'contactType'
const contactType = () => fString(
  {
    name: 'candidatePartner',
    action: (definition) => definition.rules.push(ruleSet('(or "candidatePartner")'))
  },
  {
    name: 'partner',
    action: (definition) => definition.rules.push(ruleSet('(or "partner")'))
  },
  {
    name: 'admin',
    action: (definition) => definition.rules.push(ruleSet('(or "admin")'))
  })().model(contactTypeModel)
  .prompt('What type of enquirer are you?')
  .hidden()
  .required(true)
const isContactType = isModelDefined(contactTypeModel)


// ****** Currency

const currency = () => fCurrency()


// ****** String Input

const stringInput = () => fString()()


// ****** Text Input

const textInput = () => fText()()


// ****** fileUpload

const fileUploadBuilder = () => {
  let model = 'enquiryAttachments'
  let isRequired = false
  let groupPrompt = 'Please upload all relevant documents'
  const fileGroups = []
  let includeComments = true
  let commentPrompt = 'Comments.'
  let commentPlaceholder = 'Please tell us anything you think we should know about these documents.'
  const builder = {
    model: (modelValue) => {
      model = modelValue
      return builder
    },
    comments: (prompt, placeholder) => {
      commentPrompt = prompt
      commentPlaceholder = placeholder
      return builder
    },
    includeComments: (bool) => {
      includeComments = bool
      return builder
    },
    fileGroup: (group, prompt) => {
      fileGroups.push({group, prompt})
      return builder
    },
    prompt: (prompt) => {
      groupPrompt = prompt
      return builder
    },
    required: (required) => {
      isRequired = required
      return builder
    },
    build: () => {
      assert(fileGroups.length > 0, () => `No file groups for FileUpload with model '${model}'.`)
      const specification = {
        type: 'FileUpload',
        definition: {
          model: model,
          prompt: groupPrompt,
          required: isRequired,
          config: {
            fileUploads: fileGroups.map((def) => ({fileGroup: def.group, prompt: def.prompt}))
          }
        }
      }
      if (includeComments === true) {
        specification.definition.config.comments = {
          prompt: commentPrompt,
          placeholder: commentPlaceholder
        }
      }
      return specification
    }
  }
  return builder
}


// ****** loanAmount

const loanAmountModel = 'loanAmount'
const loanAmount = () => fCurrency()
  .model(loanAmountModel)
  .prompt('Preferred loan amount?')
  .min(30_000)
  .max(200_000)
const isLoanAmount = isModelDefined(loanAmountModel)


// ****** assetPrice

const assetPriceModel = 'assetPrice'
const assetPrice = () => fCurrency()
  .model(assetPriceModel)
  .prompt('Asset purchase price?')
  .min(3_000)
  .max(1_000_000)
const isAssetPrice = isModelDefined(assetPriceModel)


// ****** proposedDeposit

const proposedDepositModel = 'proposedDeposit'
const proposedDeposit = () => fCurrency()
  .model(proposedDepositModel)
  .required(false)
  .prompt('Deposit amount?')
const isProposedDeposit = isModelDefined(proposedDepositModel)


// ****** preferredCommission

const preferredCommissionModel = 'preferredCommission'
const preferredCommission = () => fSlider()
  .model(preferredCommissionModel)
  .prompt('What is your preferred commission?')
  .min(0)
  .max(7)
  .step(0.5)
  .required(true)
const isPreferredCommission = isModelDefined(preferredCommissionModel)


// ****** loanOrigination

const loanOriginationModel = 'loanOrigination'
const loanOrigination = () =>
  radioButtons()
    .required(true)
    .model(loanOriginationModel)
    .prompt('Loan origination?')
    .button('Truepillars', 'truepillars')
    .button('Laddr', 'laddr')
const isLoanOrigination = isModelDefined(loanOriginationModel)

const loanOriginationFixed = () => fString(
  {
    name: 'truepillars',
    action: (definition) => definition.rules.push(ruleSet('(or "truepillars")'))
  },
  {
    name: 'laddr',
    action: (definition) => definition.rules.push(ruleSet('(or "laddr")'))
  })().model(loanOriginationModel).prompt('What is the enquiry origination?').hidden().required(true)


// ****** loanPurpose

const loanPurposeModel = 'loanPurpose'
const loanPurpose = createBuilder(
  {
    type: 'LoanType',
    definition: {
      model: loanPurposeModel,
      prompt: 'What is the purpose of the loan?',
      required: true,
      display: 'display',
      rules: []
    }
  })
const isLoanPurpose = isModelDefined(loanPurposeModel)

const loanPurposeAdmin = createBuilder(
  {
    type: 'LoanTypeAdmin',
    definition: {
      model: loanPurposeModel,
      prompt: 'What is the purpose of the loan?',
      required: true,
      display: 'display',
      rules: []
    }
  })

const loanPurposeFixed = () => fString(
  {
    name: 'asset',
    action: (definition) => definition.rules.push(ruleSet('(or "asset")'))
  },
  {
    name: 'loc',
    action: (definition) => definition.rules.push(ruleSet('(or "loc")'))
  },
  {
    name: 'trailBook',
    action: (definition) => definition.rules.push(ruleSet('(or "trailBook")'))
  },
  {
    name: 'cashflow',
    action: (definition) => definition.rules.push(ruleSet('(or "cashflow")'))
  },
  {
    name: 'toolsFinance',
    action: (definition) => definition.rules.push(ruleSet('(or "toolsFinance")'))
  })().model(loanPurposeModel).prompt('What is the purpose of the loan?').hidden().required(true)


// ****** PipedriveEmail -- FIXME: Is this still used?

const pipedriveEmail = createBuilder(
  {
    type: 'PipedriveEmail',
    definition: {
      model: 'referrerEmail',
      prompt: 'Referrer email address',
      required: true,
      optionalModels:
        {
          email: 'referrerEmail',
          name: ['referrerName'],
          organisationName: 'referrerCompany',
          phone: 'referrerPhone'
        }
    }
  }
)


// ****** propertyOwner

const propertyOwnerModel = 'propertyOwner'
const propertyOwner = () => radioButtons()
  .model(propertyOwnerModel)
  .prompt('Does the borrower (or guarantor) own property?')
  .button('Property backed', 'ownsProperty')
  .button('No property', 'noProperty')
  .button('Don\'t know', 'unknown')
const isPropertyOwner = isModelDefined(propertyOwnerModel)


// ****** reeceAccountNumber

const reeceTradeAccountModel = 'reeceTradeAccount'
const reeceAccountNumber = () => fString()()
  .prompt(`Reece trade account number`)
  .model(reeceTradeAccountModel)
  .required(true)
  .addRules(
    ruleValidation(
      and(isModelDefined(reeceTradeAccountModel), conjunction('regEq')(fromModel(reeceTradeAccountModel), '"^\([1-9]\\d{3,7}\|\)$"')),
      'Must be a valid account number.')
  )
const isReeceAccountNumber = isModelDefined(reeceTradeAccountModel)


// ****** referrerEmail

const referrerEmailModel = 'referrerEmail'
const referrerEmail = () => fEmail()()
  .prompt(`Your email address`)
  .model(referrerEmailModel)
  .required(true)
const isReferrerEmail = isModelDefined(referrerEmailModel)


// ****** referrerName

const referrerNameModel = 'referrerName'
const referrerName = () => fString()()
  .prompt(`Your name`)
  .model(referrerNameModel)
  .required(true)
const isReferrerName = isModelDefined(referrerNameModel)


// ****** referrerPhone

const referrerPhoneModel = 'referrerPhone'
const referrerPhone = () => fPhoneAusMobile()()
  .prompt(`Your mobile number`)
  .model(referrerPhoneModel)
  .required(true)
const isReferrerPhone = isModelDefined(referrerPhoneModel)


// ****** referrerNotes

const referrerNotesModel = 'referrerNotes'
const referrerNotes = () => fText()()
  .model(referrerNotesModel)
  .prompt('Please tell us about the business, and the reasons for the loan.')
const isReferrerNotes = isModelDefined(referrerNotesModel)


// ****** residency status

const residencyStatusModel = 'residencyStatus'
const residencyStatus = () =>
  radioButtons()
    .required(true)
    .model(residencyStatusModel)
    .prompt('Residency status')
    .button('Citizen', 'Australian Citizen')
    .button('Permanent resident', 'Australian Resident')
    .button('Visa holder', 'Australian Visa Holder')
const isResidencyStatus = isModelDefined(residencyStatusModel)


// ****** Vehicle model

const vehicleModelModel = 'vehicleModel'
const vehicleModel = () => select()
  .required(true)
  .model(vehicleModelModel)
  .prompt('Select the vehicle make and model')
  .contentId('loan-enquiry-rideshare-vehicle-models')
const isVehicleModel = isModelDefined(vehicleModelModel)


// ****** Vendor source

const vendorSourceModel = 'vendorSource'
const vendorSource = () =>
  radioButtons()
    .required(true)
    .model(vendorSourceModel)
    .prompt('Vendor source?')
    .button('Dealer', 'dealer')
    .button('Private sale', 'private')
const isVendorSource = isModelDefined(vendorSourceModel)

const vendorSourceFixed = () => fString(
  {
    name: 'dealer',
    action: (definition) => definition.rules.push(ruleSet('(or "dealer")'))
  },
  {
    name: 'privateSale',
    action: (definition) => definition.rules.push(ruleSet('(or "private")'))
  },
  {
    name: 'reece',
    action: (definition) => definition.rules.push(ruleSet('(or "reece")'))
  })().model(vendorSourceModel).prompt('What is the vendor source?').hidden().required(true)


// ****** Visa type

const immigrationVisaTypeModel = 'immigrationVisaType'
const immigrationVisaType = () => fString()()
  .prompt(`Visa type (minimum 2 years required)`)
  .model(immigrationVisaTypeModel)
  .required(true)
const isImmigrationVisaType = isModelDefined(immigrationVisaTypeModel)


// ****** nCino lead identifier

const nCinoLeadIdModel = 'leadId'
const nCinoLeadId = () => fString()()
  .model(nCinoLeadIdModel)
  .prompt('Enter the nCino lead identifier')
  .required(true)
  .rules(ruleValidation(
    `(and (isDefined (fromModel "${nCinoLeadIdModel}")) (regEq (fromModel "${nCinoLeadIdModel}") "^\([0-9a-zA-Z]+\)$"))`,
      'Must be a valid nCino lead identifier.'
    ))
const isNCinoLeadId = isModelDefined(nCinoLeadIdModel)


// ****** yearOfManufacture

const yearOfManufactureModel = 'yearOfManufacture'
const yearOfManufacture = () => fString()()
  .model(yearOfManufactureModel)
  .prompt('Year of manufacture')
  .rules(ruleValidation(
    and(isModelDefined(yearOfManufactureModel), conjunction('regEq')(fromModel(yearOfManufactureModel), '"^\\d\\d\\d\\d$"')),
    'Must be a valid year in the format YYYY.'
  ))
const isYearOfManufacture = isModelDefined(yearOfManufactureModel)

const yearOfManufactureRadioButtons = (recentYear, numberOfPreviousYears) => {
  const widget = radioButtons()
    .required(true)
    .model(yearOfManufactureModel)
    .prompt('Year of manufacture')
  if (Number.isInteger(recentYear) && Number.isInteger(numberOfPreviousYears)) {
    for (let index = 0; index < numberOfPreviousYears; index += 1) {
      const year = (recentYear - index).toFixed()
      widget.button(year, year)
    }
  }
  return widget
}


// ****** origination fee

const originationFeeModel = 'originationFee'
const originationFee = () =>
  radioButtons()
    .required(true)
    .model('originationFee')
    .prompt('Do you want to add a capitalised origination fee?')
    .button('No', 0)
    .button('Yes ($450)', 450)
const isOriginationFee = isModelDefined(originationFeeModel)


export {
  assetDescription,
  isAssetDescription,
  assetNewOrUsed,
  isAssetNewOrUsed,
  assetPrice,
  isAssetPrice,
  assetRideShareElectricVehicle,
  isAssetRideShareElectricVegicle,
  assetRideShareElectricVehicleFixed,
  borrowerEmail,
  isBorrowerEmail,
  borrowerName,
  isBorrowerName,
  borrowerPhone,
  isBorrowerPhone,
  businessFullComposite,
  isBorrowerType,
  isBusinessABN,
  isBusinessName,
  isBusinessABNName,
  isBusinessStructure,
  isTrusteeABNACN,
  isTrusteeABNACNName,
  isTrusteeName,
  isTrusteeStructure,
  contactType,
  isContactType,
  currency,
  fileUploadBuilder,
  immigrationVisaType,
  isImmigrationVisaType,
  loanAmount,
  isLoanAmount,
  loanOrigination,
  isLoanOrigination,
  loanOriginationFixed,
  loanPurpose,
  isLoanPurpose,
  loanPurposeAdmin,
  loanPurposeFixed,
  nCinoLeadId,
  isNCinoLeadId,
  originationFee,
  isOriginationFee,
  preferredCommission,
  isPreferredCommission,
  propertyOwner,
  isPropertyOwner,
  proposedDeposit,
  isProposedDeposit,
  pipedriveEmail,
  reeceAccountNumber,
  isReeceAccountNumber,
  referrerEmail,
  isReferrerEmail,
  referrerName,
  isReferrerName,
  referrerNotes,
  isReferrerNotes,
  referrerPhone,
  isReferrerPhone,
  residencyStatus,
  isResidencyStatus,
  stringInput,
  textInput,
  vehicleModel,
  isVehicleModel,
  vendorSource,
  isVendorSource,
  vendorSourceFixed,
  yearOfManufacture,
  yearOfManufactureRadioButtons,
  isYearOfManufacture
}
