import LoanRequestNavigator from './loanRequestNavigator.model'

class LoanRequestNavigatorService {
  /*@ngInject*/
  constructor($q, dateService, loanRequestsRepository, auctionsRepository, listingsRepository) {
    this.$q = $q
    this.dateService = dateService
    this.loanRequestsRepository = loanRequestsRepository
    this.auctionsRepository = auctionsRepository
    this.listingsRepository = listingsRepository
  }

  loanRequestNavigator() {
    const defer = this.$q.defer()
    this.$q.all([this.loanRequestsRepository.where({scope: 'active', size: 500}), this.auctionsRepository.where({state: 'all'})]).then(([loanRequestsData, auctionsData]) => {
      this.listingsRepository.where({scope: 'all', state: 'active'}).then((data) => {
        defer.resolve(LoanRequestNavigator.create(this.dateService, loanRequestsData.loanRequests, auctionsData.auctions, data.listings))
      }).catch(() => {
        defer.resolve(LoanRequestNavigator.create(this.dateService, loanRequestsData.loanRequests, auctionsData.auctions, []))
      })
    }).catch((e) => {
      defer.reject(e)
    })
    return defer.promise
  }
}

export default LoanRequestNavigatorService
