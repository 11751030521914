import resolveProperty from './resolveProperty'
import valueOrDefault from './valueOrDefault'
import isDefined from './isDefined'

// ****** Discriminator for Loan Enquiry Detail responses.

const isVersion1LoanEnquiryDetailResponse = (response) => response && !response.hasOwnProperty('enquiryDetails')
const isVersion2LoanEnquiryDetailResponse = (response) => response && response.hasOwnProperty('enquiryDetails')


// ****** Filter Loan Enquiry Details by status.

const loanEnquiryDetailsByStatus = (loanEnquiryDetails, ...statusValues) => {
  // const filterIn = (status, statusValues) => statusValues.some(statusValue => statusValue === status)
  // return (loanEnquiryDetails || []).filter(details => filterIn(details.status, statusValues))
  return (loanEnquiryDetails || []).filter(details => statusValues.includes(details.status))
}


// ****** Sort Loan Enquiry Details by updated at date.

const loanEnquiryDetailsSortByUpdatedAt = (loanEnquiryDetails, reverse = true) => {
  const reverseUpdatedAtOrder = (a, b) => a.updatedAt > b.updatedAt ? reverse ? -1 : 1 : (a.updatedAt < b.updatedAt ? reverse ? 1 : -1 : 0)
  return (loanEnquiryDetails || [])
    .sort(reverseUpdatedAtOrder)
}

// ****** Find Loan Enquiry Detail response answer by condition.

const loanEnquiryDetailAnswerByCondition = function(loanEnquiryDetail, conditionFn) {
  return valueOrDefault(valueOrDefault(loanEnquiryDetail.response.enquiryDetails, []).find(conditionFn), {}).answer
}

// ****** Utilities for wrangling the loan enquiry detail payload for the API.

const WIDGET_TYPE_FILE_UPLOAD = 'FileUpload'
const WIDGET_TYPE_ELECTRONIC_BANK_STATEMENTS = 'ElectronicBankStatements'
const WIDGET_TYPE_INFO = 'Info'
const WIDGET_TYPE_CONTAINER = 'Container'

const createModelFromResponse = (response) => {
  return response && response.enquiryDetails ?
    response.enquiryDetails.reduce((result, enquiryDetail) => {
      if (enquiryDetail.hasOwnProperty('name') && enquiryDetail.name.includes('.')) {
        const keys = enquiryDetail.name.split('.')

        const firstQualifier = keys[0]
        const secondQualifier = keys[1]

        if (!result[firstQualifier]) {
          result[firstQualifier] = {}
        }

        if (enquiryDetail.type === WIDGET_TYPE_FILE_UPLOAD) {
          if (!result[firstQualifier].fileUploads) {
            result[firstQualifier].fileUploads = []
          }
          result[firstQualifier].fileUploads.push(enquiryDetail.answer)
        } else {
          result[firstQualifier][secondQualifier] = enquiryDetail.answer
        }
      } else {
        result[enquiryDetail.name] = enquiryDetail.answer
      }
      return result
    }, {}) :
    {}
}

const loanEnquiryDetailLinkedBusinesses = (loanEnquiry) => {
  const businesses = []
  const createKey = (id) => `business-${businesses.length}`
  const summary = resolveProperty(loanEnquiry, 'loanEnquirySummary')

  Array.of('borrower', 'trustee').forEach(type => {
    if (summary[type]) {
      businesses.push({
        id: createKey(businesses.length),
        type: type,
        abnAcn: summary[type].abnAcn,
        name: summary[type].businessName,
        tradingSince: summary[type].businessTradingSince,
      })
    }
  })

  loanEnquiryDetailsByStatus(loanEnquiryDetailsSortByUpdatedAt(loanEnquiry.loanEnquiryDetails, false), 'complete')
    .filter((loanEnquiryDetail) => loanEnquiryDetail.type === 'linked-business')
    .forEach((loanEnquiryDetail) => {
      businesses.push({
        id: createKey(businesses.length),
        type: loanEnquiryDetailAnswerByCondition(loanEnquiryDetail, (qanda) => qanda.name === 'type'),
        abnAcn: loanEnquiryDetailAnswerByCondition(loanEnquiryDetail, (qanda) => qanda.name === 'businessABNACN' || qanda.name === 'businessABN'),
        name: loanEnquiryDetailAnswerByCondition(loanEnquiryDetail, (qanda) => qanda.name === 'businessName'),
        tradingSince: loanEnquiryDetailAnswerByCondition(loanEnquiryDetail, (qanda) => qanda.name === 'businessTradingSince')
      })
    })
  return businesses
}

const createPayloadAnswer = (name, type, question, answer, display) => {
  const result = {name, type, question, answer}
  if (display) {
    result.display = display
  }
  return result
}

const transformWidget = (result, widget, model) => {
  if (widget.render) {
    switch (widget.type) {
      case WIDGET_TYPE_ELECTRONIC_BANK_STATEMENTS:
        widget.widgets.forEach((electronicBankStatementsWidget) => {
          const answer = model[widget.modelFieldName] && model[widget.modelFieldName][electronicBankStatementsWidget.modelFieldName]
          result.push(createPayloadAnswer(`${widget.modelFieldName}.${electronicBankStatementsWidget.modelFieldName}`,
            electronicBankStatementsWidget.type,
            electronicBankStatementsWidget.prompt,
            answer))
        })
        break
      case WIDGET_TYPE_FILE_UPLOAD:
        widget.fileUploadConfig.fileUploads.forEach((fileUpload) => {
          const answer = model[widget.modelFieldName] && model[widget.modelFieldName].fileUploads.find((u) => u.fileGroup === fileUpload.fileGroup) ||
            {prompt: fileUpload.prompt, fileGroup: fileUpload.fileGroup, uploads: []}
          result.push(createPayloadAnswer(`${widget.modelFieldName}.${fileUpload.fileGroup}`, WIDGET_TYPE_FILE_UPLOAD, fileUpload.prompt, answer))
        })
        const comments = resolveProperty(model, widget.modelFieldName, 'comments')
        if (isDefined(comments)) {
          result.push(createPayloadAnswer(`${widget.modelFieldName}.comments`, 'Text', widget.fileUploadConfig.comments.prompt, comments))
        }
        break
      case WIDGET_TYPE_INFO:
        break
      case WIDGET_TYPE_CONTAINER:
        widget.widgets.forEach(subwidget => transformWidget(result, subwidget, model))
        break
      default:
        result.push(createPayloadAnswer(widget.modelFieldName, widget.type, widget.prompt, model[widget.modelFieldName], widget.display))
        break
    }
  }
  return result
}

const createPayload = (model, compiledWidgets) => {
  const result = []
  compiledWidgets.forEach(compiledWidget => transformWidget(result, compiledWidget, model))
  return result
}

const REFERRER_MAP = [
  {destination: 'email', source: 'referrerEmail'},
  {destination: 'name', source: 'referrerName'},
  {destination: 'company', source: 'referrerCompany'},
  {destination: 'phone', source: 'referrerPhone'},
]

const createPayloadReferrerDetails = (model) => {
  return REFERRER_MAP.reduce((referrer, {destination, source}) => {
    if (resolveProperty(model, source)) {
      referrer[destination] = model[source]
    }
    return referrer
  }, {})
}

const extractEntity = (loanEnquiryDetails, fieldNamesList, target = {}) => {
  const resolveDetail = (details) => resolveProperty(details, 'response', 'enquiryDetails')
  const mostRecentLoanEnquiryDetailsWithCandidate = loanEnquiryDetailsSortByUpdatedAt(loanEnquiryDetailsByStatus(loanEnquiryDetails, 'complete'))
    .find((loanEnquiryDetails) => {
      const details = resolveDetail(loanEnquiryDetails)
      return details && details.find(detail => fieldNamesList.includes(detail.name))
    })
  return mostRecentLoanEnquiryDetailsWithCandidate ?
    resolveDetail(mostRecentLoanEnquiryDetailsWithCandidate)
      .filter(details => fieldNamesList.includes(details.name))
      .reduce((details, detail) => {
        details[detail.name] = detail.answer
        return details
      }, target) : undefined
}

export {
  createModelFromResponse,
  createPayload,
  createPayloadReferrerDetails,
  isVersion1LoanEnquiryDetailResponse,
  isVersion2LoanEnquiryDetailResponse,
  loanEnquiryDetailsByStatus,
  loanEnquiryDetailsSortByUpdatedAt,
  loanEnquiryDetailAnswerByCondition,
  loanEnquiryDetailLinkedBusinesses,
  extractEntity
}
