import angular from 'angular'

import formsModule from './forms/forms'
import apiFormModule from './apiForm/apiForm'
import contentfulFormModule from './contentfulForm/contentfulForm'
import widgetsModule from './dynamicForm/widgets/widgets'
import dynamicFieldDisplayModule from './dynamicFieldDisplay/dynamicFieldDisplay'
import dynamicFormModule from './dynamicForm/dynamicForm'
import editableDisplayModule from './editableDisplay/editableDisplay'
import patchFormModule from './patchForm/patchForm'
import transcludeReplaceModule from './transcludeReplace/transcludeReplace'
import registrationFormModule from './registrationForm/registrationForm'
import attachmentUploadModule from './attachmentUpload/attachmentUpload'
import embeddedFormModule from './embeddedEnquiryForm/embeddedEnquiryForm'
import userIdentityCheckManagement from './userIdentityCheckManagement/userIdentityCheckManagement'

import navbarModule from './navbar/navbar'
import bigfootModule from './bigfoot/bigfoot'
import pageMetaModule from './pageMeta/pageMeta'
import entityNameModule from './entityName/entityName'

import mediaSizeModule from './mediaSize/mediaSize'
import fittedModule from './fitted/fitted'
import proportionalBarModule from './proportionalBar/proprotionalBar'
import videoDialogModule from './videoDialog/videoDialog'
import countdownTimerModule from './countdownTimer/countdownTimer'
import gaClickModule from './gaClick/gaClick'
import dotPointModule from './dotPoint/dotPoint'
import cdnAssetModule from './cdnAsset/cdnAsset'
import requireRoleModule from './requireRole/requireRole'
import ifTenantModule from './ifTenant/ifTenant'
import ifApplicationSettingModule from './ifApplicationSetting/ifApplicationSetting'
import pairedRatingModule from './pairedRating/pairedRating'
import combinedRatingModule from './combinedRating/combinedRating'
import stepBallModule from './stepBall/stepBall'
import numberedStepModule from './numberedStep/numberedStep'
import heroPanelModule from './heroPanel/heroPanel'
import iconPanelModule from './iconPanel/iconPanel'
import contentItemModule from './contentItem/contentItem'
import compileMarkdownModule from './compileMarkdown/compileMarkdown'
import sectionAccordionModule from './sectionAccordion/sectionAccordion'
import investorStatusChangeModule from './investorStatus/investorStatusChange/investorStatusChange'
import investorStatusDisplayModule from './investorStatus/investorStatusDisplay/investorStatusDisplay'
import biometricIdentityCheckStatusDisplayModule from './biometricIdentityCheckStatusDisplay/biometricIdentityCheckStatusDisplay'
import brandingColourSelectionModule from './branding/brandingColourSelection/brandingColourSelection'
import referrerAssistanceModule from './referrerAssistance/referrerAssistance'

import tenantAnchorModule from './tenantInformation/tenantAnchor/tenantAnchor'
import tenantInlineModule from './tenantInformation/tenantInline/tenantInline'

import contactRequestFormModule from './contactRequestForm/contactRequestForm'

import helpMateModule from './helpMate/helpMate'
import helpBoxModule from './helpBox/helpBox'
import alertListModule from './alertList/alertList'
import contentfulAssetModule from './contentfulAsset/contentfulAsset'

import bpayDetailsModule from './bpayDetails/bpayDetails'
import journalEntriesViewModule from './journalEntriesView/journalEntriesView'
import loanSummaryModule from './loanSummary/loanSummary'
import abnLookupModule from './abnLookup/abnLookup'
import abTestModule from './abTest/abTest'
import markdownEditorModule from './mardownEditor/markdownEditor'
import photoBoothModule from './photoBooth/photoBooth'
import currentBidsModule from './currentBids/currentBids'
import loanPartsModule from './loanParts/loanParts'
import mediaCarouselModule from './mediaCarousel/mediaCarousel'
import fileUploadModule from './fileUpload/fileUpload'
import electronicBankStatementsModule from './electronicBankStatements/electronicBankStatements'
import reportCreditorWatchAsicExtractModule from './reports/creditorWatchAsicExtractDisplay/creditorWatchAsicExtractDisplay'
import withdrawalRequestOriginModule from './withdrawalRequests/withdrawalRequestOrigin/withdrawalRequestOrigin'

import reportInteractionModule from './reportService/reportInteraction/reportInteraction'

export default angular.module('app.common', [
  formsModule.name,
  apiFormModule.name,
  contentfulFormModule.name,
  widgetsModule.name,
  dynamicFieldDisplayModule.name,
  dynamicFormModule.name,
  editableDisplayModule.name,
  patchFormModule.name,
  transcludeReplaceModule.name,
  registrationFormModule.name,
  attachmentUploadModule.name,
  embeddedFormModule.name,

  navbarModule.name,
  bigfootModule.name,
  pageMetaModule.name,
  entityNameModule.name,

  mediaSizeModule.name,
  fittedModule.name,
  proportionalBarModule.name,
  videoDialogModule.name,
  countdownTimerModule.name,
  gaClickModule.name,
  dotPointModule.name,
  cdnAssetModule.name,
  requireRoleModule.name,
  ifTenantModule.name,
  ifApplicationSettingModule.name,
  pairedRatingModule.name,
  combinedRatingModule.name,
  stepBallModule.name,
  numberedStepModule.name,
  iconPanelModule.name,
  contentItemModule.name,
  heroPanelModule.name,
  compileMarkdownModule.name,
  sectionAccordionModule.name,
  investorStatusChangeModule.name,
  investorStatusDisplayModule.name,
  biometricIdentityCheckStatusDisplayModule.name,
  brandingColourSelectionModule.name,
  referrerAssistanceModule.name,

  tenantAnchorModule.name,
  tenantInlineModule.name,

  contactRequestFormModule.name,

  helpMateModule.name,
  helpBoxModule.name,
  alertListModule.name,
  contentfulAssetModule.name,

  bpayDetailsModule.name,
  journalEntriesViewModule.name,
  loanSummaryModule.name,
  abnLookupModule.name,

  abTestModule.name,
  markdownEditorModule.name,
  photoBoothModule.name,
  currentBidsModule.name,
  loanPartsModule.name,
  mediaCarouselModule.name,
  fileUploadModule.name,
  electronicBankStatementsModule.name,
  reportCreditorWatchAsicExtractModule.name,
  userIdentityCheckManagement.name,
  withdrawalRequestOriginModule.name,

  reportInteractionModule.name
])
