import template from './dateSelect.pug'
import controller from './dateSelect.controller'
import './dateSelect.scss'

export default () => {
  return {
    template: template({}),
    controller,
    restrict: 'E',
    controllerAs: 'vm',
    scope: {},
    require: ['^form', '^?patchForm', '^?formRepeatSectionTransclusion'],
    bindToController: {
      model: '=',
      name: '@',
      label: '@?',
      maxDate: '=?',
      dynamic: '=?',
      required: '=?'
    },
    link: (scope, ele, attrs, controls) => {
      const [form, patchForm, formRepeatSectionTransclusion] = controls
      scope.vm.form = form
      scope.vm.patchForm = patchForm
      scope.vm.formRepeatSectionTransclusion = formRepeatSectionTransclusion
    }
  }
}
