import objectAssign from 'object-assign/index'

class Resource {
  static create($http, $q, resourceFactory, data) {
    return new Resource($http, $q, resourceFactory, data)
  }

  /*@ngInject*/
  constructor($http, $q, resourceFactory, data) {
    this._$http = $http
    this._$q = $q
    this._resourceFactory = resourceFactory
    this._promises = {}
    this._update(data)
  }

  patch(patches) {
    return this._$http.patch(this.self.href, patches).then((response) => {
      return this._update(response.data)
    })
  }

  save() {
    return this._$http.put(this.self.href, this.toJson()).then((response) => {
      return this._update(response.data)
    })
  }

  promise(name) {
    if (!this._promises[name] && this._links[name]) {
      this._loadLink(name)
    }
    return this._promises[name]
  }

  reload() {
    if (!this._reloading) {
      this._reloading = this._$http.get(this.self.href).then((response) => {
        this._promises = {}
        this._update(response.data)
        delete(this._reloading)
        return this
      })
    }
    return this._reloading
  }

  delete() {
    return this._$http.delete(this.self.href).then(()=> this)
  }

  performAction(action, data) {
    return this._$http.put(this.self.href + '/' + action, data).then((response) => {
      return this._update(response.data)
    })
  }

  setResource(name, resource) {
    this.clearResource(name)
    this[name] = resource
    this._promises[name] = this._$q((resolve) => resolve(this[name]))
  }

  clearResource(name) {
    delete this[name]
    delete this._promises[name]
  }

  reloadResource(name) {
    this.clearResource(name)
    return this.promise(name)
  }

  toJson() {
    return this._removePrivateAttributes(JSON.parse(JSON.stringify(this, (key, value) => {
      return key.indexOf && key.indexOf('_') === 0 ? undefined : value
    })))
  }

  // private
  _removePrivateAttributes(clone) {
    for (let prop in clone) {
      if (angular.isObject(clone[prop])) {
        clone[prop] = this._removePrivateAttributes(clone[prop])
      }
      if (this._isIgnoredForJson(prop)) delete clone[prop]
    }
    return clone
  }

  _update(data) {
    this._assignData(data)

    if (this._embedded) {
      this._addEmbedded()
    }
    if (this._links) {
      this._addLinked()
    }
    return this
  }

  _addEmbedded() {
    for (let name in this._embedded) {
      this._addResource(name, this._embedded[name])
    }
  }

  _addLinked() {
    for (let name in this._links) {
      this._addLinkProperty(name)
    }
  }

  _addLinkProperty(name) {
    this.clearResource(name)
    Object.defineProperty(this, name, {
      configurable: true, get: () => this._loadLink(name)
    })
  }

  _loadLink(name) {
    if (!this._promises[name]) {
      this._promises[name] = this._$http.get(this._links[name].href).then((response) => {
        return this._addResource(name, response.data)
      })
    }
  }

  _addResource(name, data) {
    let resource
    if (data instanceof Array) {
      resource = this._arrayOfResources(data)
    } else if (data[name] instanceof Array) {
      resource = this._arrayOfResources(data[name])
    } else {
      resource = this._resourceFactory.getResource(data)
    }
    this.setResource(name, resource)
    return this._promises[name]
  }

  _arrayOfResources(array) {
    return array.map((datum) => this._resourceFactory.getResource(datum))
  }

  _assignData(data) {
    objectAssign(this, data)
    if (this._links && this._links.self) {
      this.self = this._links.self
      delete this._links.self
    }
  }

  _isIgnoredForJson(propertyName) {
    return propertyName === 'self'
  }
}

export default Resource
