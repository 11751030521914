import {
  assetDescription,
  assetRideShareElectricVehicle,
  borrowerName,
  borrowerPhone,
  borrowerEmail,
  businessFullComposite,
  contactType,
  currency,
  fileUploadBuilder,
  assetPrice,
  loanAmount,
  preferredCommission,
  loanPurposeAdmin,
  propertyOwner,
  referrerName,
  referrerPhone,
  referrerEmail,
  referrerNotes
} from '../dynamicEnquiryFormHelpers'
import {
  container,
  helpContent,
  radioButtons,
  ruleRender,
  ruleSet
} from '../../../dynamicForm/helpers/dynamicFormHelpers'

const conjunction = (rel) => (...predicates) => `(${rel} ` + predicates.join(' ') + ')'
const and = conjunction('and')

const isAssetPrice = '(isDefined (fromModel "assetPrice"))'
const isAssetDescription = '(isDefined (fromModel "assetDescription"))'
const isAssetRideShareElectricVehicle = '(isDefined (fromModel "assetRideShareElectricVehicle"))'
const isReferrerNotes = '(isDefined (fromModel "referrerNotes"))'
const isVendorSource = '(isDefined (fromModel "vendorSource"))'
const isBusinessEntity = '(and (isDefined (fromModel "businessABN")) (isDefined (fromModel "businessName")))'
const isReferrer = and('(isDefined (fromModel "referrerEmail"))', '(isDefined (fromModel "referrerName"))', '(isDefined (fromModel "referrerPhone"))')
const isBorrower = and('(isDefined (fromModel "borrowerEmail"))', '(isDefined (fromModel "borrowerName"))')

const equipmentFinanceBrokerEnquiryForm = {
  title: 'Broker equipment finance scenario',
  model: 'equipment-finance-enquiry-application',
  actionLabel: 'Submit enquiry',
  widgets: [
    container()
      .prompt('Equipment finance application')
      .classes('border')
      .widgets(
        loanPurposeAdmin().required(true).display('none').rules(ruleSet('(or "asset")')).build(),
        contactType().candidatePartner().build(),
        helpContent().contentId('enquiry-form-asset-application-main').build(),
        container()
          .boxed(5)
          .widgets(
            assetPrice()
              .prompt('Asset purchase price')
              .build(),
            assetDescription()
              .required(true)
              .rules(ruleRender(isAssetPrice))
              .build(),
            assetRideShareElectricVehicle()
              .required(true)
              .rules(ruleRender(isAssetDescription))
              .build(),
            radioButtons()
              .required(true)
              .model('vendorSource')
              .prompt('Vendor source?')
              .button('Dealer', 'dealer')
              .button('Private sale', 'private')
              .rules(ruleRender(isAssetRideShareElectricVehicle))
              .build(),
            currency()
              .model('proposedDeposit')
              .prompt('Deposit amount (if applicable)')
              .required(false)
              .rules(ruleRender(isVendorSource))
              .build(),
            loanAmount()
              .prompt('Financed amount')
              .min(3000)
              .required(false)
              .rules(ruleRender(isVendorSource), ruleSet('(or (subtract (fromModel "assetPrice") (fromModel "proposedDeposit")) (fromModel "assetPrice"))'))
              .display('read-only')
              .build(),
            preferredCommission()
              .prompt('What is your preferred brokerage?')
              .min(0)
              .max(7)
              .step(0.5)
              .required(true)
              .rules(ruleRender(isVendorSource))
              .build(),
            radioButtons()
              .required(true)
              .model('originationFee')
              .prompt('Do you want to add a capitalised origination fee?')
              .button('No', 0)
              .button('Yes ($450)', 450)
              .rules(ruleRender(isVendorSource))
              .build()
          ).build(),
        container()
          .boxed(5)
          .rules(ruleRender(isVendorSource))
          .widgets(
            ...businessFullComposite(),
            referrerNotes().prompt('Describe the business background, and the reason for the loan').required(true).rules(ruleRender(isBusinessEntity)).build()
          ).build(),
        container()
          .boxed(5)
          .prompt('Broker details')
          .rules(ruleRender(and(isBusinessEntity, isReferrerNotes)))
          .widgets(
            referrerName().build(),
            referrerPhone().prompt('Your broker mobile number').build(),
            referrerEmail().prompt('Your broker email address').build()
          ).build(),
        container()
          .boxed(5)
          .prompt('Borrower details')
          .rules(ruleRender(isReferrer))
          .widgets(
            helpContent().contentId('enquiry-form-borrower-contact-direct').build(),
            borrowerName().build(),
            borrowerPhone().required(false).build(),
            borrowerEmail().build(),
            radioButtons()
              .model('hasGuarantor')
              .prompt('Is there a guarantor, other than the borrower, for this loan?')
              .button('Guarantor', 'yes')
              .button('Not required', 'no')
              .build(),
            container()
              .boxed(5)
              .prompt('Guarantor details')
              .rules(ruleRender(and('(isDefined (fromModel "hasGuarantor"))', '(eq (fromModel "hasGuarantor") "yes")')))
              .widgets(
                borrowerName().model('guarantorName').prompt('Guarantor\'s name').build(),
                borrowerPhone().model('guarantorPhone').prompt('Guarantor\'s mobile').required(false).build(),
                borrowerEmail().model('guarantorEmail').prompt('Guarantor\'s email address').build()).build(),
            propertyOwner().prompt('Does the person providing the guarantee on the loan own property?').required(true).build(),
          ).build(),
        container()
          .boxed(5)
          .prompt('Documents')
          .rules(ruleRender(and(isReferrer, isBorrower)))
          .widgets(
            fileUploadBuilder()
              .fileGroup('atoICA', 'ATO portal report (Integrated Client Account) - optional')
              .fileGroup('assetInvoices', 'Invoice for the asset')
              .fileGroup('councilRates', 'Council rates notices, if property backed')
              .fileGroup('financialDocuments', 'Recent financial statements for the business (optional)')
              .fileGroup('otherDocuments', 'Any other relevant documents')
              .includeComments(false)
              .build())
          .build())
      .build()
  ]
}

export default equipmentFinanceBrokerEnquiryForm
