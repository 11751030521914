class Repository {
  /*@ngInject*/
  constructor($http, $q, resourceFactory, link) {
    this._$http = $http
    this._$q = $q
    this._resourceFactory = resourceFactory
    this._link = link
  }

  all() {
    return this._fetch()
  }

  where(conditions) {
    return this._fetch({params: conditions})
  }

  getById(id) {
    return this._link.then((link) => this.getByHref(`${link.href}/${id}`))
  }

  getByHref(href) {
    return this._$http.get(href).then((response) => this._resource(response.data))
  }

  create(entity = {}) {
    return this._link.then((link) => this._$http.post(link.href, entity).then((response) => this._resource(response.data)))
  }

  // private

  _fetch(config = {}) {
    return this._link.then((link) => this._$http.get(link.href, config).then((response) => this._wrapResources(response.data)))
  }

  _wrapResources(data) {
    for (let name in data) {
      if (data.hasOwnProperty(name) && data[name] instanceof Array) {
        data[name] = data[name].map((resource) => this._resource(resource))
      }
    }
    return data
  }

  _resource(data) {
    return this._resourceFactory.getResource(data)
  }
}

export default Repository
