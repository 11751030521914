import {
  borrowerName,
  borrowerPhone,
  borrowerEmail,
  businessFullComposite,
  contactType,
  fileUploadBuilder,
  loanAmount,
  loanPurposeAdmin,
  propertyOwner,
  reeceAccountNumber,
  referrerName,
  referrerPhone,
  referrerEmail,
  referrerNotes
} from '../dynamicEnquiryFormHelpers'
import {
  container,
  helpContent,
  radioButtons,
  ruleRender,
  ruleSet
} from '../../../dynamicForm/helpers/dynamicFormHelpers'

const conjunction = (rel) => (...predicates) => `(${rel} ` + predicates.join(' ') + ')'

const and = conjunction('and')

const isLoanAmount = '(isDefined (fromModel "loanAmount"))'
const isReferrerNotes = '(isDefined (fromModel "referrerNotes"))'

const isBusinessEntity = '(and (isDefined (fromModel "businessABN")) (isDefined (fromModel "businessName")))'

const isReferrer = and('(isDefined (fromModel "referrerEmail"))',
  '(isDefined (fromModel "referrerName"))',
  '(isDefined (fromModel "referrerPhone"))')
const isBorrower = and('(isDefined (fromModel "borrowerEmail"))',
  '(isDefined (fromModel "borrowerName"))')

const lineOfCreditAdminEnquiryForm = {
  title: 'Admin line of credit',
  model: 'line-of-credit-loan-enquiry-application',
  actionLabel: 'Submit enquiry',
  widgets: [
    container()
      .prompt('Line of credit loan enquiry')
      .classes('border')
      .widgets(
        loanPurposeAdmin().required(true).display('none').rules(ruleSet('(or "loc")')).build(),
        contactType().admin().build(),
        helpContent().contentId('enquiry-form-line-of-credit-application-main').build(),
        container()
          .boxed(5)
          .widgets(
            radioButtons()
              .required(true)
              .model('loanOrigination')
              .prompt('Loan origination?')
              .button('Truepillars', 'truepillars')
              .button('Laddr', 'laddr')
              .build(),
            reeceAccountNumber().prompt('Reece trade account number')
              .addRules(ruleRender('(eq (fromModel "loanOrigination") "laddr")'))
              .build()
          ).build(),
        container()
          .boxed(5)
          .widgets(
            loanAmount().prompt('Line of credit limit?').min(5000).max(100000).build()
          ).build(),
        container()
          .boxed(5)
          .rules(ruleRender(isLoanAmount))
          .widgets(
            ...businessFullComposite(),
            referrerNotes().prompt('Describe the business background, and the reason for the loan').required(true).rules(ruleRender(isBusinessEntity)).build()
          ).build(),
        container()
          .boxed(5)
          .prompt('Broker details')
          .rules(ruleRender(and(isBusinessEntity, isReferrerNotes)))
          .widgets(
            referrerName().build(),
            referrerPhone().prompt('Your broker mobile number').build(),
            referrerEmail().prompt('Your broker email address').build()).build(),
        container()
          .boxed(5)
          .prompt('Borrower details')
          .rules(ruleRender(isReferrer))
          .widgets(
            helpContent().contentId('enquiry-form-business-borrower-contact-direct').build(),
            borrowerName().build(),
            borrowerPhone().required(false).build(),
            borrowerEmail().build(),
            radioButtons()
              .model('hasGuarantor')
              .prompt('Is there a guarantor, other than the borrower, for this loan?')
              .button('Guarantor', 'yes')
              .button('Not required', 'no')
              .build(),
            container()
              .boxed(5)
              .prompt('Guarantor details')
              .rules(ruleRender(and('(isDefined (fromModel "hasGuarantor"))', '(eq (fromModel "hasGuarantor") "yes")')))
              .widgets(
                borrowerName().model('guarantorName').prompt('Guarantor\'s name').build(),
                borrowerPhone().model('guarantorPhone').prompt('Guarantor\'s mobile').required(false).build(),
                borrowerEmail().model('guarantorEmail').prompt('Guarantor\'s email address').build()).build(),
            propertyOwner().prompt('Does the person providing the guarantee on the loan own property?').required(true).build(),
          ).build(),
        container()
          .boxed(5)
          .prompt('Documents')
          .rules(ruleRender(and(isReferrer, isBorrower)))
          .widgets(
            fileUploadBuilder()
              .fileGroup('reeceAccountFinancials', 'Reece account financials')
              .fileGroup('laddrPrimaryApplication', 'Laddr primary application')
              .fileGroup('laddrSecondaryApplication', 'Laddr secondary application')
              .fileGroup('otherDocuments', 'Any other relevant documents')
              .build())
          .build())
      .build()
  ]
}

export default lineOfCreditAdminEnquiryForm
