import formOwner from './formOwner'
import formSettings from './formSettings'
import formInvestor from './formInvestor'
import noopPromiseBasedActionGenerator from '../../../../common/dynamicForm/helpers/noopPromiseBasedActionGenerator'

class InvestorManageController {
  /*@ngInject*/
  constructor($scope, $q, $mdToast, featureTogglesService) {
    this.name = 'investor-manage'
    this.isSelfieToggleEnabled = () => featureTogglesService.can('enableSelfie')

    this.transitions = ['accept', 'verify', 'reject', 'delete']
    this.status = {
      owner: 'loading',
      investorSettings: 'loading',
      investor: 'loading',
      investorStatusChanges: 'loading'
    }

    this.clipboard = undefined
    this.statusChangeOrder = '-createdAt'
    this.investorStatusChanges = []

    this.changesReorder = () => {
      const key = this.statusChangeOrder.replace('-', '')
      const [lessThan, greaterThan] = this.statusChangeOrder.startsWith('-') ? [1, -1] : [-1, 1]
      this.investorStatusChanges.sort((a, b) => {
        const [aValue, bValue] = [a[key], b[key]]
        if (aValue < bValue) {
          return lessThan
        } else if (aValue > bValue) {
          return greaterThan
        } else {
          return a['id'] < b['id'] ? lessThan : (a['id'] > b ['id'] ? greaterThan : 0)
        }
      })
    }

    const noopSaveAction = noopPromiseBasedActionGenerator($q)

    const initOwner = () => {
      $scope.$watch(() => (this.investor && this.investor.owner) || this.applicant, (applicantNew, applicantOld) => {
        if (applicantNew) {
            // this.owner = owner
            this.owner = applicantNew
            this.manageOwner = {
              definition: formOwner,
              model: {
                honorific: this.owner.honorific ? this.owner.honorific : '',
                firstName: this.owner.firstName,
                middleName: this.owner.middleName,
                lastName: this.owner.lastName,
                address: this.owner.canonicalAddress,
                phone: this.owner.phone,
                dob: this.owner.dob,
              },
              showSubmit: true,
              saveAction: noopSaveAction,
              submitAction: (model, compiledWidgets) => {
                return this.owner.reload().then(owner => {
                  this.owner = owner
                  this.owner.honorific = model.honorific
                  this.owner.firstName = model.firstName
                  this.owner.middleName = model.middleName
                  this.owner.lastName = model.lastName
                  this.owner.canonicalAddress = model.address
                  this.owner.phone = model.phone
                  this.owner.dob = model.dob
                  return this.owner.save().then(() => {
                    if (this.investor) {
                      return this.investor.reload()
                    }
                  })
                }).catch(angular.noop())
              },
              actionLabel: 'Update owner'
            }
            this.status.owner = 'loaded'
          // }).catch(angular.noop())
        }
      })
    }

    const initInvestorSetting = () => {
      $scope.$watch(() => this.investor && this.investor.investorSetting, () => {
        if (this.investor && this.investor.investorSetting) {
          this.settings = this.investor.investorSetting
          this.manageSettings = {
            definition: formSettings,
            model: {
              smallWithdrawals: this.settings.permissions.smallWithdrawals ? this.settings.permissions.smallWithdrawals : false
            },
            showSubmit: true,
            saveAction: noopSaveAction,
            submitAction: (model, compiledWidgets) => {
              return this.settings.reload().then(settings => {
                this.settings = settings
                this.settings.permissions.smallWithdrawals = model.smallWithdrawals
                return this.settings.save().then(() => this.investor.reload())
              })
            },
            actionLabel: 'Update settings'
          }
          this.status.investorSettings = 'loaded'
        }
      })
    }

    const initInvestor = () => {
      $scope.$watch(() => this.investor, () => {
        if (this.investor) {
          this.manageInvestor = {
            definition: formInvestor,
            model: {
              tfn: this.investor.tfn,
              name: this.investor.name,
              entityType: this.investor.type,
              taxJurisdiction: this.investor.taxJurisdiction
            },
            showSubmit: true,
            saveAction: noopSaveAction,
            submitAction: (model, compiledWidgets) => {
              return this.investor.reload().then(investor => {
                this.investor = investor
                this.investor.tfn = model.tfn
                this.investor.name = model.name
                this.investor.taxJurisdiction = model.taxJurisdiction
                return this.investor.save().then(() => this.investor.reload())
              })
            },
            actionLabel: 'Update investor'
          }
          this.status.investor = 'loaded'
        }
      })
    }

    const initStatus = () => {
      $scope.$watch(() => this.investor && this.investor.investorStatusChanges, () => {
        if (this.investor) {
          this.status.investor = 'loaded'
        }
        if (this.investor && this.investor.investorStatusChanges) {
          this.investorStatusChanges = this.investor.investorStatusChanges.map((change) => change)
          this.status.investorStatusChanges = 'loaded'
        }
      })
    }

    this.$onInit = () => {
      initOwner()
      initInvestorSetting()
      initInvestor()
      initStatus()
    }
  }

  get ownerIsLoaded() {
    return this.status.owner === 'loaded'
  }

  get investorSettingsIsLoaded() {
    return this.status.investorSettings === 'loaded'
  }

  get investorIsLoaded() {
    return this.status.investor === 'loaded'
  }

  get investorStatusIsLoaded() {
    return this.status.investorStatusChanges === 'loaded'
  }
}

export default InvestorManageController
