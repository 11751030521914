class LoanPartsController {
  /*@ngInject*/
  constructor($scope, $q, localStorageService, loanPartListingsService) {
    this.name = 'loan-parts'
    this.limitOptions = [5, 10, 20, 50]
    this.page = 1
    this.order = 'loanPart.nextRepaymentSummary.expectedDate'

    localStorageService.bind($scope, 'vm.limit', 10, 'loan-parts.limit')
    this.redeemToggles = new Map()

    $scope.$watch(this.investor, () => {
      if (this.investor) {
        this.loading = this.investor.promise('loanParts').then(loanParts => {
          const compositeLoanPartIndexes = new Map()
          let compositeLoanPartIndex = -1
          this.compositeLoanParts = loanParts.map(loanPart => {
            compositeLoanPartIndex += 1
            compositeLoanPartIndexes.set(loanPart.id, compositeLoanPartIndex)
            return loanPartListingsService.createCompositeLoanPart(loanPart)
          })

          loanPartListingsService.loadActiveListingsForInvestor(this.investor.id).then(data => {
            const listingsByLoanPartId = data.listings.reduce((mappedListings, listing) => {
              if (!mappedListings.has(listing.loanPartId)) {
                mappedListings.set(listing.loanPartId, [])
              }
              mappedListings.get(listing.loanPartId).push(listing)
              return mappedListings
            }, new Map())

            listingsByLoanPartId.forEach((listings, loanPartId) => {
              const availableIndex = compositeLoanPartIndexes.get(loanPartId)
              if (availableIndex >= 0) {
                this.compositeLoanParts[availableIndex].setListings(listings)
              }
            })
          })
        })
      } else {
        this.loading = $q.defer().promise
      }
    })
  }

  toggleRedeemRow(loanPartId) {
    if (this.redeemToggles.has(loanPartId)) {
      this.redeemToggles.set(loanPartId, !this.redeemToggles.get(loanPartId))
    } else {
      this.redeemToggles.set(loanPartId, true)
    }
  }

  displayRedeemRow(loanPartId) {
    return this.redeemToggles.has(loanPartId) && this.redeemToggles.get(loanPartId)
  }

  listingsControlIcon(compositeLoanPart) {
    if (this.displayRedeemRow(compositeLoanPart.loanPart.id)) {
      return compositeLoanPart.listingsContext.listings.length > 0 ? 'expand_less' : 'expand_less'
    } else {
      return compositeLoanPart.listingsContext.listings.length > 0 ? 'assignment' : 'expand_more'
    }
  }

  convertToClass(className) {
    return className ? className.replace(/ /g, '-') : null
  }
}

export default LoanPartsController
